<template>
    <div class="main-content-area" v-touch:swipe.right="backButton">
        <div class="main-content-toolbar">
            <v-toolbar>
                <v-btn size="x-large" variant="text" @click="backButton">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
            </v-toolbar>
            <v-toolbar>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('truckerGate') }}</h3>
                        <span class="job-status">{{ $t('gateIn') }}</span>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-toolbar> <v-icon class="btn-icon" color="green">mdi-truck-delivery</v-icon></v-toolbar>
        </div>

        <div class="center-flex" v-if="!ready">
            <DataLoading :type="4" />
        </div>

        <div v-if="ready" class="main-content-playground">
            <!-- First Row Buttons -->
            <div class="gate-in">
                <v-row>
                    <!-- Reference Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isReferenceButtonDisabled" @click="selectReferenceButton">
                            <div class="button-content">
                                <v-icon large>{{ referenceIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('reference') }}</span>
                                <span>{{ selectedReference || $t('selectReference') }}</span>
                            </div>
                        </v-btn>
                    </v-col>

                    <!-- Container Number Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isContainerButtonDisabled" @click="selectContainerButton">
                            <div class="button-content">
                                <v-icon large>{{ containerIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('containerNumber') }}</span>
                                <span>{{ selectedContainerNumber || $t('selectContainerNumber') }}</span>
                            </div>
                        </v-btn>
                    </v-col>

                    <!-- ISO Code Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isIsoCodeButtonDisabled" @click="selectIsoCodeButton">
                            <div class="button-content">
                                <v-icon large>{{ isoCodeIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('isoCode') }}</span>
                                <span>{{ selectedIsoCode || $t('selectIsoCode') }}</span>
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div>&nbsp;</div>

            <!-- Second Row Buttons -->
            <div class="gate-in">
                <v-row>
                    <!-- Customer / Operator Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isCustomerButtonDisabled" @click="selectCustomerButton">
                            <div class="button-content">
                                <v-icon large>{{ customerIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('customerOperator') }}</span>
                                <span>{{ selectedCustomer || $t('selectCustomer') }}</span>
                            </div>
                        </v-btn>
                    </v-col>

                    <!-- Trucker Company Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isTruckerCompanyButtonDisabled" @click="selectTruckerCompanyButton">
                            <div class="button-content">
                                <v-icon large>{{ truckerCompanyIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('truckerCompany') }}</span>
                                <span>{{ selectedTruckerCompany || $t('selectTruckerCompany') }}</span>
                            </div>
                        </v-btn>
                    </v-col>

                    <!-- Licence Plate Button -->
                    <v-col cols="12" sm="4" md="4" class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            :disabled="isLicencePlateButtonDisabled" @click="selectLicencePlate">
                            <div class="button-content">
                                <v-icon large>{{ licencePlateIcon }}</v-icon>
                                <span class="container-btn-text">{{ $t('licencePlate') }}</span>
                                <span>{{ selectedLicencePlate || $t('selectLicencePlate') }}</span>
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div>&nbsp;</div>

            <!-- Confirm Gate In Button -->
            <div class="gate-in">
                <v-row>
                    <v-col class="rowBtnCol center">
                        <v-btn :disabled="isConfirmButtonDisabled" color="primary" size="large" width="100%"
                            height="100%" class="white--text" @click="confirmGateIn">
                            <span class="container-btn-text">{{ $t('confirmGateIn') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
                <v-btn icon="mdi-circle" size="small" color="light-green darken-1" variant="text"></v-btn>
                <v-btn size="x-small" variant="text" @click="reloadList">
                    <h2>Status: online</h2>
                </v-btn>
            </v-toolbar>
        </div>
    </div>

    <!-- Reference Popup Dialog -->
    <v-dialog v-model="referenceDialog" fullscreen>
        <v-card>
            <v-toolbar>
                <v-btn variant="text" @click="closeReferenceDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('enterReference') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <v-text-field :label="$t('reference')" v-model="referenceInput" class="large-input" solo hide-details
                    full-width @keyup.enter="validateReference"></v-text-field>
                <v-btn color="primary" size="x-large" width="100%" height="50%" class="white--text"
                    @click="validateReference">
                    <span class="container-btn-text">{{ $t('submitReference') }}</span>
                </v-btn>
                <div v-if="referenceError" class="error">{{ referenceError }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Container Number Popup Dialog -->
    <v-dialog v-model="containerDialog" fullscreen>
        <v-card>
            <v-toolbar>
                <v-btn variant="text" @click="closeContainerDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('enterContainerNumber') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-text-field :label="$t('containerNumber')" v-model="containerInput" class="large-input" solo
                    hide-details full-width maxlength="11" @keydown.enter="validateContainerNumber"></v-text-field>
                <v-btn color="primary" size="x-large" width="100%" height="50%" class="white--text"
                    @click="validateContainerNumber">
                    <span class="container-btn-text">{{ $t('submitContainer') }}</span>
                </v-btn>
                <div v-if="containerError" class="error">{{ containerError }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- ISO Code Popup Dialog -->
    <v-dialog v-model="isoCodeDialog" fullscreen>
        <v-card style="display: flex; flex-direction: column; height: 100vh;">
            <v-toolbar flat>
                <v-btn variant="text" @click="closeIsoCodeDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('selectIsoCode') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <!-- Main content area -->
            <v-card-text style="flex: 1; overflow-y: auto;">
                <!-- Input field for filtering ISO Codes -->
                <v-text-field :label="$t('filterIsoCode')" v-model="isoCodeFilter" class="large-input" solo hide-details
                    full-width></v-text-field>
                <!-- List of filtered ISO Codes -->
                <v-list>
                    <v-list-item v-for="isoCode in filteredIsoCodes" :key="isoCode.code"
                        @click="selectIsoCodeFromList(isoCode)">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ isoCode.code }} - {{ isoCode.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ isoCode.survey_type__survey_type.name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!-- Error Message -->
                <div v-if="isoCodeError" class="error">{{ isoCodeError }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Customer / Operator Popup Dialog -->
    <v-dialog v-model="customerDialog" fullscreen>
        <v-card style="display: flex; flex-direction: column; height: 100vh;">
            <v-toolbar flat>
                <v-btn variant="text" @click="closeCustomerDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('selectCustomerOperator') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <!-- Main content area -->
            <v-card-text style="flex: 1; overflow-y: auto;">
                <!-- Input field for filtering Customers / Operators -->
                <v-text-field :label="$t('filterCustomerOperator')" v-model="customerFilter" class="large-input" solo
                    hide-details full-width></v-text-field>
                <!-- List of filtered Customers / Operators -->
                <v-list>
                    <v-list-item v-for="customer in filteredCustomers" :key="customer.id"
                        @click="selectCustomerFromList(customer)">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ customer.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!-- Error Message -->
                <div v-if="customerError" class="error">{{ customerError }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Trucker Company Popup Dialog -->
    <v-dialog v-model="truckerCompanyDialog" fullscreen>
        <v-card style="display: flex; flex-direction: column; height: 100vh;">
            <v-toolbar flat>
                <v-btn variant="text" @click="closeTruckerCompanyDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('selectTruckerCompany') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <!-- Main content area -->
            <v-card-text style="flex: 1; overflow-y: auto;">
                <!-- Input field for filtering Trucker Companies -->
                <v-text-field :label="$t('filterTruckerCompany')" v-model="truckerCompanyFilter" class="large-input"
                    solo hide-details full-width></v-text-field>
                <!-- List of filtered Trucker Companies -->
                <v-list>
                    <v-list-item v-for="(company, index) in filteredTruckerCompanies" :key="company.id + '-' + index"
                        @click="selectTruckerCompanyFromList(company)">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ company.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!-- Error Message -->
                <div v-if="truckerCompanyError" class="error">{{ truckerCompanyError }}</div>
            </v-card-text>
            <!-- Add New Trucker Company Button -->
            <v-card-actions>
                <v-btn color="primary" size="large" width="100%" class="white--text"
                    @click="openAddTruckerCompanyDialog">
                    <span class="container-btn-text">{{ $t('addNewTruckerCompany') }}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Add New Trucker Company Dialog -->
    <v-dialog v-model="addTruckerCompanyDialog" fullscreen>
        <v-card>
            <v-toolbar flat>
                <v-btn variant="text" @click="closeAddTruckerCompanyDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('addNewTruckerCompany') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <!-- Input fields for name and email -->
                <v-text-field :label="$t('truckerCompanyName')" v-model="newTruckerCompanyName" class="large-input" solo
                    hide-details full-width></v-text-field>
                <v-text-field :label="$t('truckerCompanyEmail')" v-model="newTruckerCompanyEmail" class="large-input"
                    solo hide-details full-width type="email"></v-text-field>
                <!-- Submit Button -->
                <v-btn color="primary" size="large" width="100%" class="white--text" @click="submitNewTruckerCompany">
                    <span class="container-btn-text">{{ $t('submitTruckerCompany') }}</span>
                </v-btn>
                <!-- Error Message -->
                <div v-if="addTruckerCompanyError" class="error">{{ addTruckerCompanyError }}</div>
            </v-card-text>
        </v-card>
    </v-dialog>


    <!-- Licence Plate Popup Dialog -->
    <v-dialog v-model="licencePlateDialog" fullscreen>
        <v-card>
            <v-toolbar>
                <v-btn variant="text" @click="closeLicencePlateDialog">
                    <v-icon left>mdi-arrow-left</v-icon>
                    {{ $t('goBack') }}
                </v-btn>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('enterLicencePlate') }}</h3>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-text-field :label="$t('licencePlate')" v-model="licencePlateInput" class="large-input" solo
                    hide-details full-width @keyup.enter="submitLicencePlate"></v-text-field>
                <v-btn color="primary" size="x-large" width="100%" height="50%" class="white--text"
                    @click="submitLicencePlate">
                    <span class="container-btn-text">{{ $t('submitLicencePlate') }}</span>
                </v-btn>
                <!-- No validation needed, so no error message -->
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Gate In Confirmation Popup -->
    <v-dialog v-model="gateInConfirmationDialog" persistent transition="slide-up-transition" width="300">
        <v-card class="confirmation-popup">
            <v-card-text class="text-center">
                <v-icon class="confirmation-icon" color="green">mdi-truck-delivery</v-icon>
                <h2>{{ $t('gateInConfirmed') }}</h2>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script setup>
import {
    ref,
    inject,
    computed,
    onActivated,
    getCurrentInstance,
} from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useUtilities } from '@/composables/useUtilities';
import { useTransporter } from '@/composables/useTransporter';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { useNotification } from '@/composables/useNotification';
import { useI18n } from 'vue-i18n'; // Import useI18n

// Use vue-i18n
const { t } = useI18n();

// INJECTIONS
const axios = inject('axios');
const { utilities } = useUtilities();

// VARIABLES
let position = ref('');
let containerId = ref(null);
let referenceId = ref(null);
let selectedReference = ref(null);
let selectedContainerNumber = ref(null);
let selectedIsoCode = ref(null);
let selectedIsoCodeId = ref(null);
let selectedCustomer = ref(null);
let selectedCustomerId = ref(null);
let selectedTruckerCompany = ref(null);
let selectedTruckerCompanyId = ref(null);
let selectedLicencePlate = ref(null);

let newTruckerComment = ref(null);
let depotId = ref(null);

const inboundCarrierOptions = ref([]);
const isoCodeOptions = ref([]);
const matchedShippingLineOptions = ref([]);

// For Add New Trucker Company Dialog
const addTruckerCompanyDialog = ref(false);
const newTruckerCompanyName = ref('');
const newTruckerCompanyEmail = ref('');
const addTruckerCompanyError = ref('');

// CONSTANTS
const store = useAppStore();
const componentId = getCurrentInstance().uid;
const router = useRouter();
const ready = ref(false);
const showFilter = ref(false);

// COMPOSABLES
const { transporter } = useTransporter();
const { advancedAxios } = useAdvancedAxios(axios);
const { notification } = useNotification();

// REACTIVE VARIABLES FOR DIALOG
const referenceDialog = ref(false);
const referenceInput = ref('');
const referenceError = ref('');

const containerDialog = ref(false);
const containerInput = ref('');
const containerError = ref('');

const isoCodeDialog = ref(false);
const isoCodeFilter = ref('');
const isoCodeError = ref('');

const customerDialog = ref(false);
const customerFilter = ref('');
const customerError = ref('');


const truckerCompanyDialog = ref(false);
const truckerCompanyFilter = ref('');
const truckerCompanyError = ref('');


const licencePlateDialog = ref(false);
const licencePlateInput = ref('');

const gateInConfirmationDialog = ref(false);


// COMPUTED

const filteredIsoCodes = computed(() => {
    if (!isoCodeFilter.value) {
        return isoCodeOptions.value;
    }
    const filterValue = isoCodeFilter.value.toLowerCase();
    return isoCodeOptions.value.filter(isoCode =>
        isoCode.code.toLowerCase().includes(filterValue) ||
        isoCode.name.toLowerCase().includes(filterValue) ||
        (isoCode.survey_type__survey_type.name &&
            isoCode.survey_type__survey_type.name.toLowerCase().includes(filterValue))
    );
});

const filteredCustomers = computed(() => {
    if (!customerFilter.value) {
        return matchedShippingLineOptions.value;
    }
    return matchedShippingLineOptions.value.filter(customer =>
        customer.name.toLowerCase().includes(customerFilter.value.toLowerCase())
    );
});

const filteredTruckerCompanies = computed(() => {
    if (!truckerCompanyFilter.value) {
        return inboundCarrierOptions.value;
    }
    return inboundCarrierOptions.value.filter(company =>
        company.name.toLowerCase().includes(truckerCompanyFilter.value.toLowerCase())
    );
});


// Computed properties for button disabled states
const isReferenceButtonDisabled = computed(() => isContainerSelected.value && !isReferenceSelected.value);
const isContainerButtonDisabled = computed(() => false);
const isIsoCodeButtonDisabled = computed(() => !(isContainerSelected.value && !isReferenceSelected.value));
const isCustomerButtonDisabled = computed(() => !(isIsoCodeSelected.value && !isReferenceSelected.value));
const isTruckerCompanyButtonDisabled = computed(() => {
    if (isReferenceSelected.value && isContainerSelected.value) {
        return false; // Enabled after Reference and Container are selected
    } else if (!isReferenceSelected.value && isCustomerSelected.value) {
        return false; // Enabled after Customer is selected in the second path
    } else {
        return true; // Otherwise disabled
    }
});
const isLicencePlateButtonDisabled = computed(() => !isTruckerCompanySelected.value);
const isConfirmButtonDisabled = computed(() => {
    return !(
        // First Path: Reference Flow
        (isReferenceSelected.value &&
            isContainerSelected.value &&
            isTruckerCompanySelected.value &&
            isLicencePlateSelected.value) ||
        // Second Path: Container Flow
        (!isReferenceSelected.value &&
            isContainerSelected.value &&
            isIsoCodeSelected.value &&
            isCustomerSelected.value &&
            isTruckerCompanySelected.value &&
            isLicencePlateSelected.value)
    );
});

const isReferenceSelected = computed(() => selectedReference.value !== null);
const isContainerSelected = computed(() => selectedContainerNumber.value !== null);
const isIsoCodeSelected = computed(() => selectedIsoCode.value !== null);
const isCustomerSelected = computed(() => selectedCustomer.value !== null);
const isTruckerCompanySelected = computed(() => selectedTruckerCompany.value !== null);
const isLicencePlateSelected = computed(() => selectedLicencePlate.value !== null);

// Icons
const referenceIcon = computed(() => {
    return isReferenceSelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});
const containerIcon = computed(() => {
    return isContainerSelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});
const isoCodeIcon = computed(() => {
    return isIsoCodeSelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});
const customerIcon = computed(() => {
    return isCustomerSelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});
const truckerCompanyIcon = computed(() => {
    return isTruckerCompanySelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});
const licencePlateIcon = computed(() => {
    return isLicencePlateSelected.value ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline';
});

// EVENTS
onActivated(() => {
    ready.value = false;
    let transportData = transporter.getData(componentId);
    selectedContainerNumber.value = transportData ? transportData.unit_number : null;
    containerId.value = transportData ? transportData.id : null;
    selectedReference.value = transportData ? transportData.reference : null;
    referenceId.value = transportData ? transportData.ref_id : null;
    console.log(transportData);
    ready.value = true;
});

// METHODS

const confirmGateIn = async () => {
    if (!isConfirmButtonDisabled.value) {
        try {
            // Disable the confirm button to prevent multiple submissions
            isConfirmButtonDisabled.value = true;

            // Prepare data for creating the container
            const containerData = {
                unit_number: selectedContainerNumber.value.trim().toUpperCase(),
                depot_id: store.depotId || 1, // Replace with actual depot ID
                matched_leasing_company: -1,
                matched_shipping_line: selectedCustomerId.value || null,
                operator_name: selectedCustomer.value || null,
                iso_code: selectedIsoCodeId.value || null,
                gate_in_date: new Date().toISOString(),
                gate_out_date: null,
                position: '',
                ib_actual_visit: selectedLicencePlate.value || null,
                ib_actual_visit_mode: 'truck',
                ob_actual_visit: null,
                ob_actual_visit_mode: null,
                condition: null,
                last_on_hire_date: null,
                comment: newTruckerComment.value,
                floor: null,
                color: null,
                temperature: null,
                humidity: null,
                ventilation: null,
                inbound_carrier_id: selectedTruckerCompanyId.value || null,
                outbound_carrier_id: null,
                stock_container_status_id: 1,
                blocked: 0,
            };

            // Step 1: Create the container
            const containerResponse = await axios.post('/yamax', containerData);
            const createdContainer = containerResponse.data.model;

            // Update the containerId with the newly created container's ID
            containerId.value = createdContainer.id;

            // Prepare data for creating the task
            const taskData = {
                container_id: containerId.value,
                reference_id: referenceId.value || null,
                depot_id: depotId.value || 552,
                type: 'return',
                status: 'open',
                position: '',
                comment: 'Trucker Gate-In.',
                licence_plate: selectedLicencePlate.value,
                carrier_id: selectedTruckerCompanyId.value || null,
            };

            // Step 2: Create the task
            await addTask(taskData);

            // Step 3: Show success message and confirmation popup
            store.addNotificationMessage(t('gateInConfirmed'), 'success');
            gateInConfirmationDialog.value = true;

            // Automatically close the popup after 2 seconds and reset selections
            setTimeout(() => {
                gateInConfirmationDialog.value = false;
                resetSelections();
                isConfirmButtonDisabled.value = false;
            }, 2000);

        } catch (error) {
            console.error('Error during gate-in confirmation:', error);
            store.addNotificationMessage(t('errorConfirmingGateIn'), 'error');
            isConfirmButtonDisabled.value = false;
        }
    } else {
        store.addNotificationMessage(t('completeAllSelections'), 'error');
    }
};

const addTask = (taskData) => {
    return advancedAxios.sendRequest(taskData.container_id, { method: 'post', url: '/api/yamaxTasks', data: taskData })
        .then(function (response) {
            // Handle response if necessary
            store.addNotificationMessage(t('taskAddedSuccessfully'), 'success');
        })
        .catch(function (error) {
            console.error('Error adding task:', error);
            store.addNotificationMessage(t('errorAddingTask'), 'error');
            throw error; // Re-throw the error to be caught in confirmGateIn
        });
};

const validateReference = async () => {
    try {
        // Clear previous errors
        referenceError.value = '';

        // Make a GET request to the API endpoint with the reference as a query parameter
        const response = await axios.get('/references/check', {
            params: { reference: referenceInput.value.trim() },
        });

        const data = response.data;

        if (data.exists) {
            // Reference exists, populate the necessary fields
            selectedReference.value = referenceInput.value.trim();
            referenceId.value = data.reference.id; 
            selectedIsoCode.value = data.reference.survey_size_type_id;
            selectedIsoCodeId.value = data.reference.survey_size_type_id;
            selectedCustomer.value = data.reference.operator__company.name;
            selectedCustomerId.value = data.reference.operator_id;
            depotId.value = data.reference.depot_id;

            // Close the dialog
            closeReferenceDialog();
        } else {
            // Reference does not exist
            referenceError.value = t('referenceNotFound');
        }
    } catch (error) {
        console.error(error);
        referenceError.value = t('errorValidatingReference');
    }
};

const validateContainerNumber = async () => {
    const newValue = containerInput.value.toUpperCase().trim();

    // Validate container number format
    const containerRegex = /^[A-Z]{4}\d{7}$/;
    if (newValue.length === 11 && containerRegex.test(newValue)) {
        // Verify check digit
        const baseContainerId = newValue.substring(0, 10);
        const providedCheckDigit = newValue.charAt(10);
        const calculatedCheckDigit = utilities.generateUnitNumberCheckNumber(baseContainerId).toString();

        if (providedCheckDigit !== calculatedCheckDigit) {
            containerError.value = t('containerWrongCheckDigit', { containerNumber: newValue });
            store.addNotificationMessage(
                t('containerWrongCheckDigit', { containerNumber: `<b>${newValue}</b>` }),
                'warning'
            );
        } else {
            // Valid container number
            // Check if the container is already in stock
            try {
                const response = await axios.get('/stockContainer/check', {
                    params: { unit_number: newValue }
                });

                if (response.data && response.data.model && Object.keys(response.data.model).length > 0) {
                    // Container is already in stock
                    containerError.value = 'Container is already in stock';
                    store.addNotificationMessage(
                        'Container is already in stock',
                        'error'
                    );
                } else {
                    // Container is not in stock, proceed
                    // Assign submodels to variables
                    inboundCarrierOptions.value = response.data.submodels.inbound_carrier_id;
                    isoCodeOptions.value = response.data.submodels.iso_code;
                    matchedShippingLineOptions.value = response.data.submodels.matched_shipping_line;

                    selectedContainerNumber.value = newValue;
                    containerError.value = '';
                    closeContainerDialog();
                }
            } catch (error) {
                console.error(error);
                containerError.value = 'Error checking container status';
                store.addNotificationMessage(
                    'Error checking container status',
                    'error'
                );
            }
        }
    } else if (newValue.length === 10 && /^[A-Z]{4}\d{6}$/.test(newValue)) {
        // Append calculated check digit
        const checkDigit = utilities.generateUnitNumberCheckNumber(newValue).toString();
        const fullContainerId = newValue + checkDigit;

        // Check if the container is already in stock
        try {
            const response = await axios.get('/stockContainer/check', {
                params: { unit_number: fullContainerId }
            });

            if (response.data && response.data.model && Object.keys(response.data.model).length > 0) {
                // Container is already in stock
                containerError.value = 'Container is already in stock';
                store.addNotificationMessage(
                    'Container is already in stock',
                    'error'
                );
            } else {
                // Container is not in stock, proceed

                // Assign submodels to variables
                inboundCarrierOptions.value = response.data.submodels.inbound_carrier_id;
                isoCodeOptions.value = response.data.submodels.iso_code;
                matchedShippingLineOptions.value = response.data.submodels.matched_shipping_line;

                selectedContainerNumber.value = fullContainerId;
                containerError.value = '';
                closeContainerDialog();
            }
        } catch (error) {
            console.error(error);
            containerError.value = 'Error checking container status';
            store.addNotificationMessage(
                'Error checking container status',
                'error'
            );
        }
    } else {
        containerError.value = t('invalidContainerFormat');
    }
};

const openAddTruckerCompanyDialog = () => {
    addTruckerCompanyDialog.value = true;
};

const closeAddTruckerCompanyDialog = () => {
    addTruckerCompanyDialog.value = false;
    newTruckerCompanyName.value = '';
    newTruckerCompanyEmail.value = '';
    addTruckerCompanyError.value = '';
};

const submitNewTruckerCompany = async () => {
    // Validate inputs
    if (!newTruckerCompanyName.value.trim()) {
        addTruckerCompanyError.value = t('truckerCompanyNameRequired');
        return;
    }
    if (!newTruckerCompanyEmail.value.trim()) {
        addTruckerCompanyError.value = t('truckerCompanyEmailRequired');
        return;
    }
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newTruckerCompanyEmail.value.trim())) {
        addTruckerCompanyError.value = t('invalidEmail');
        return;
    }

    try {
        // Clear previous error
        addTruckerCompanyError.value = '';

        // Prepare data to send to backend
        const payload = {
            name: newTruckerCompanyName.value.trim(),
            email: newTruckerCompanyEmail.value.trim(),
        };

        // Send POST request to backend to create new Trucker Company
        const response = await axios.post('/truckerCompanies', payload);

        const newCompany = response.data;

        // Update the inboundCarrierOptions list
        inboundCarrierOptions.value.push(newCompany);

        // Select the new company
        selectedTruckerCompany.value = newCompany.name;
        selectedTruckerCompanyId.value = newCompany.id;

        newTruckerComment.value = "New trucker company."
        // Close both dialogs
        closeAddTruckerCompanyDialog();
        closeTruckerCompanyDialog();
    } catch (error) {
        console.error('Error adding Trucker Company:', error);
        addTruckerCompanyError.value = t('errorAddingTruckerCompany');
    }
};


const resetSelections = () => {
    selectedReference.value = null;
    referenceId.value = null;
    selectedContainerNumber.value = null;
    containerId.value = null;
    selectedIsoCode.value = null;
    selectedIsoCodeId.value = null;
    selectedCustomer.value = null;
    selectedCustomerId.value = null;
    selectedTruckerCompany.value = null;
    selectedTruckerCompanyId.value = null;
    selectedLicencePlate.value = null;
    newTruckerComment.value = null;
    depotId.value = null;
};

const backButton = (params) => {
    if (showFilter.value == true) {
        toggleFilter();
    } else {
        router.push({ name: 'TgApp' });
    }
};

// Methods to handle selections

const selectTruckerCompanyButton = async () => {
    // If inboundCarrierOptions is empty, fetch it
    if (inboundCarrierOptions.value.length === 0) {
        try {
            const response = await axios.get('/stockContainer/submodels');
            inboundCarrierOptions.value = response.data.inbound_carrier_id;

            // Auto-select if only one option is available
            if (inboundCarrierOptions.value.length === 1) {
                selectTruckerCompanyFromList(inboundCarrierOptions.value[0]);
                return;
            }
        } catch (error) {
            console.error('Error fetching Trucker Companies:', error);
            store.addNotificationMessage(
                t('errorFetchingTruckerCompanies'),
                'error'
            );
            return;
        }
    }

    // Auto-select if only one option is available
    if (inboundCarrierOptions.value.length === 1) {
        selectTruckerCompanyFromList(inboundCarrierOptions.value[0]);
    } else {
        truckerCompanyDialog.value = true;
    }
};

const closeTruckerCompanyDialog = () => {
    truckerCompanyDialog.value = false;
    truckerCompanyFilter.value = '';
    truckerCompanyError.value = '';
};

const selectTruckerCompanyFromList = (company) => {
    selectedTruckerCompany.value = company.name;
    selectedTruckerCompanyId.value = company.id;
    closeTruckerCompanyDialog();
};

const selectCustomerButton = async () => {
    // If matchedShippingLineOptions is empty, fetch it
    if (matchedShippingLineOptions.value.length === 0) {
        try {
            const response = await axios.get('/stockContainer/submodels');
            matchedShippingLineOptions.value = response.data.matched_shipping_line;

            // Auto-select if only one option is available
            if (matchedShippingLineOptions.value.length === 1) {
                selectCustomerFromList(matchedShippingLineOptions.value[0]);
                return;
            }
        } catch (error) {
            console.error('Error fetching Customers:', error);
            store.addNotificationMessage(
                t('errorFetchingCustomers'),
                'error'
            );
            return;
        }
    }

    // Auto-select if only one option is available
    if (matchedShippingLineOptions.value.length === 1) {
        selectCustomerFromList(matchedShippingLineOptions.value[0]);
    } else {
        customerDialog.value = true;
    }
};

const closeCustomerDialog = () => {
    customerDialog.value = false;
    customerFilter.value = '';
    customerError.value = '';
};

const selectCustomerFromList = (customer) => {
    selectedCustomer.value = customer.name;
    selectedCustomerId.value = customer.id;
    closeCustomerDialog();
};

const selectIsoCodeButton = async () => {
    // If isoCodeOptions is empty, fetch it
    if (isoCodeOptions.value.length === 0) {
        try {
            const response = await axios.get('/stockContainer/submodels');
            isoCodeOptions.value = response.data.iso_code;
        } catch (error) {
            console.error('Error fetching ISO Codes:', error);
            store.addNotificationMessage(
                t('errorFetchingIsoCodes'),
                'error'
            );
            return;
        }
    }
    isoCodeDialog.value = true;
};

const closeIsoCodeDialog = () => {
    isoCodeDialog.value = false;
    isoCodeFilter.value = '';
    isoCodeError.value = '';
};

const selectIsoCodeFromList = (isoCode) => {
    selectedIsoCode.value = `${isoCode.code} - ${isoCode.name}`;
    selectedIsoCodeId.value = isoCode.code; // Using code as the identifier
    closeIsoCodeDialog();
};

const selectLicencePlate = () => {
    licencePlateDialog.value = true;
};

const closeLicencePlateDialog = () => {
    licencePlateDialog.value = false;
    licencePlateInput.value = '';
};

const submitLicencePlate = () => {
    selectedLicencePlate.value = licencePlateInput.value.trim();
    closeLicencePlateDialog();
};

const selectReferenceButton = () => {
    referenceDialog.value = true;
};

const closeReferenceDialog = () => {
    referenceDialog.value = false;
    referenceInput.value = '';
    referenceError.value = '';
};

const selectContainerButton = () => {
    containerDialog.value = true;
};

const closeContainerDialog = () => {
    containerDialog.value = false;
    containerInput.value = '';
    containerError.value = '';
};

const toggleFilter = () => {
    showFilter.value = !showFilter.value;
};
</script>

<style scoped>
/* Global box-sizing reset */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.main-content-area {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    /* Prevents unwanted scrollbars */
}

.main-content-playground {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.toolbar-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.job-status {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #ffffff;
    margin-left: 8px;
}

.gate-in {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.v-row {
    flex-grow: 1;
    display: flex;
}

.rowBtnCol {
    flex-grow: 1;
    display: flex;
}


.container-btn-text {
    font-weight: bold;
    text-align: center;
    text-transform: none;
}

.center {
    align-items: center;
    justify-content: center;
}

.error {
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    color: red;
    margin-top: 1em;
}

.large-input {
    margin-bottom: 1.25em;
    /* 20px */
}

.large-input ::v-deep .v-label {
    font-size: 1.5em;
    /* 24px */
}

.large-input ::v-deep .v-input__slot {
    font-size: 1.5em;
    /* 24px */
    height: 3.75em;
    /* 60px */
}

.large-input ::v-deep input {
    font-size: 2em;
    /* 24px */
    height: 3.75em;
    /* 60px */
}

.large-input ::v-deep .v-field__field {
    padding: 1.25em 1em;
    /* 20px 16px */
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-icon {
    font-size: 5em;
}

.confirmation-popup {
    background-color: #e0f7fa;
    /* Light cyan background */
}

.confirmation-icon {
    font-size: 5em;
}

.confirmation-popup h2 {
    margin-top: 0.5em;
    font-size: 2em;
    font-weight: bold;
}

@media (max-width: 600px) {
    .container-btn-text {
        font-size: 1em;
    }
}

@media (min-width: 601px) {
    .container-btn-text {
        font-size: 2em;
    }
}
</style>
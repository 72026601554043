<template>
    <v-app>
        <QuickNotifications />
        
        <v-main v-touch:longtap="loadAppSettings">
            <router-view v-slot="{ Component }">
                <KeepAlive :exclude="['YamaxStock']">
                    <component :is="Component" />
                </KeepAlive>
            </router-view>            
        </v-main>
    </v-app>
</template>



<script setup>


    //Imports
    import { ref, reactive, onMounted, inject } from 'vue'
    import "ag-grid-community/dist/styles/ag-grid.css";
    import "ag-grid-community/dist/styles/ag-theme-alpine.css";
    import { useAppStore } from '@/stores/app'
    import { useRouter } from 'vue-router';

    import('./assets/sass/global_mobile.sass');
    
    

    //Injections
    const axios=inject('axios');
    const router = useRouter();



    //Constants
    const store = useAppStore()
    const data=reactive({
        loading: true,
        dialog: false,
    })
    const csrfReceived=ref(false);



    //INTERCEPTORS
    axios.setupInterceptors(store, router);



    //EVENTS
    onMounted(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            csrfReceived.value=true;
            store.user.csrfToken = response.config.headers['X-XSRF-TOKEN'];
            router.push({ name: 'LoginPage', params: { next: 'TgApp' } });
        }).catch(function (error) { });
    });
  
  
  
    //METHODS
    function loadAppSettings(){
        //router.push({ name: 'AppSettings' });
    }
</script>


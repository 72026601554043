import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridToggleFilter from '@/components/aggrid/filters/GridToggleFilter';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import GridSetFilter from '@/components/aggrid/filters/GridSetFilter';
import GridSetFloatingFilter from '@/components/aggrid/floating_filters/GridSetFloatingFilter';
import GridNumberEditor from '@/components/aggrid/cell_editors/GridNumberEditor';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import GridDateFilter from '@/components/aggrid/filters_new/GridDateFilter';
import { useUtilities } from  '@/composables/useUtilities';
import GridIconRenderer from '@/components/aggrid/cell_renderers/GridIconRenderer'
import GridDateFloatingFilter from '@/components/aggrid/floating_filters_new/GridDateFloatingFilter'
import GridSelectAndTextEditor from '@/components/aggrid/cell_editors/GridSelectAndTextEditor'
import GridToggleFloatingFilterYesNo from '@/components/aggrid/floating_filters/GridToggleFloatingFilterYesNo'
import GridSelectWithIconFilter from '@/components/aggrid/filters_new/GridSelectWithIconFilter';
import GridSelectFloatingFilter from '@/components/aggrid/floating_filters_new/GridSelectFloatingFilter';
import GridTextFloatingFilter from '@/components/aggrid/floating_filters_new/GridTextFloatingFilter.vue';
import GridTextFilter from '@/components/aggrid/filters_new/GridTextFilter';
import _ from 'lodash';
import ReferenceListValidator from '@/validations/ReferenceListValidator';



export function useContainerReferenceListDefinition(props, store, dayJs) {


    
    //COMPOSABLES
    const { utilities } = useUtilities(store);



    const getContainerReferenceListDefinition = () => {

        const { common } = useCommon();

        const insuranceAndInsuranceAmount = (params) => {
            let insurance = '';
            let amount = '';
            if (params.data.insurance_type === 'full') {
                insurance = 'Full';
            } else if (params.data.insurance_type === 'amount') {
                insurance = 'Amount';
                amount = " ( " + params.data.insurance_amount + "$ )";
            } else if (params.data.insurance_type === 'none') {
                insurance = 'None';
            }
            if (insurance === '') { return ''; }
            return insurance + amount;
        }

        const temperatureTypeAndTemperature = (params) => {
            let temperature_type = '';
            let temperature = '';
            if (params.data.temperature_type === 'multi-temp') {
                temperature_type = 'Multi-temp';
            } else if (params.data.temperature_type === 'exact') {
                temperature_type = 'Exact';
                temperature = " ( " + params.data.temperature + "° )";
            }

            return temperature_type + temperature;
        }

        let columnDefs = [
            {   headerName: 'New', field: 'new',
                hide: true
            },            
            {
                headerName: 'status', field: 'valid',
                hide: true
            },
            {
                headerName: '', field: 'attributes',
                hide: true,
                filter: false,
                editable: false,
                valueFormatter: function (params) {
                    if (params.data.gate_out_date !== null) {
                        return 'readonly';
                    }
                    return '';
                },
                cellRenderer: GridIconRenderer,
                cellRendererParams: {
                    'valueToUse': 'valueFormatted',
                    'valueList': {
                        'readonly': { icon: 'mdi-lock-outline', classes: 'error', message: "This container already has a Gate-Out date and you can't edit or delete it." }
                    }
                },
                width: 10

            },
            {
                headerName: 'Id', field: 'id',
                hide: true,
                editable: false,
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            {
                headerName: 'Status', field: 'stock_container_status_id',
                editable: false,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Unit Number', field: 'unit_number',
                /*editable: function (params) {
                    return (props.reference.id !== '' && _.has(params, 'data.stock_container_status__stock_container_status') && params.data.stock_container_status__stock_container_status.name === 'Stock')
                },*/

                //To edit unit number in a text box use below code
                cellEditor: props.reference.type=='return' ? GridTextEditor : GridSelectEditorNeo,

                cellEditorParams: props.reference.type=='return' ? {
                    pattern: "^[a-z|A-Z]{4}[0-9]{7}$",
                    emptyStringIsNull: true,
                    onUpdate: (oldValue, newValue) => {
                        if (newValue.length == oldValue.length - 1 && newValue == oldValue.substring(0, oldValue.length - 1)) {
                            //It's a character delete so don't do anything
                            return newValue;
                        } else {
                            if (newValue && newValue.length == 10) {
                                return newValue + utilities.generateUnitNumberCheckNumber(newValue);
                            } else if (newValue && newValue.length == 11) {
                                //Next two lines need to work when we have a way of notifying the user that the check number might be wrong
                                let newValue10 = newValue.substring(0, 10);
                                let newValue10Checknumber = utilities.generateUnitNumberCheckNumber(newValue10);
                                if (newValue.substring(10) != newValue10Checknumber) {
                                    store.addNotificationMessage("Unit number <b>" + newValue + "</b> has wrong check number", 'warning');
                                }
                                return newValue;
                            } else {
                                return newValue;
                            }
                        }
                    }
                } : {
                    valueList: [],
                    loadFromUrl: {
                        url:   "/yamax?iso_code="+ props.reference.survey_size_type_id +
                                "&matched_shipping_line=" + props.reference.operator_id +
                                "&freight_kind=" + props.reference.freight_kind +
                                "&ready=true&status=" + (props.reference.type=='return' ? 'Preannounced' : 'In Stock') +
                                "&container_older=" + props.reference.container_older + 
                                "&container_younger=" + props.reference.container_younger,
                        id: 'id',
                        name: 'unit_number'
                    },
                    noOptions: 'No Containers'
                },
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },

                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {headerName: 'Inspection Criteria', field: 'inspection_criteria',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('inspection_criteria') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },            
            {
                headerName: 'DPP', 
                field: 'dpp',
                hide: props.reference.type === 'release',
                editable: function(params){
                    return true;
                },                
                cellEditor: GridNumberEditor,
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Container Condition', field: 'condition', 
                hide: false,
                width: 140,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },            
            {
                headerName: 'Run State', 
                field: 'run_state',
                editable: false,
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Repair Criteria', 
                field: 'repair_criteria',
                editable: false,
                //Editor
                cellEditor: 'agTextCellEditor',
                //Filter
                filter: GridTextFilter,
                filterParams: { },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Gate-In Date', field: 'gate_in_date',
                hide: props.reference.type === 'release',
                cellEditor: DatePicker,
                cellEditorParams: {
                    config: {
                        //minDate: new Date(),
                        maxDate: dayJs().tz(store.user.config.timezone).toDate(),
                        /*disable: [
                            function (date) {
                                return (date > dayJs());
                            }
                        ],*/
                        autoSetDate: 'now'
                    }
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return !params.value ? '' : utilities.formatDateTime(params.value);
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {},
                //Custom
            },
            {
                headerName: 'Gate-Out Date', field: 'gate_out_date',
                hide: props.reference.type === 'return',
                cellEditor: DatePicker,
                cellEditorParams: {
                    config: {
                        maxDate: dayJs().tz(store.user.config.timezone).toDate(),
                        /*disable: [
                            function (date) {
                                return (date > dayJs());
                            }
                        ],*/
                        //autoSetDate: 'now'
                    }
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return !params.value ? '' : utilities.formatDateTime(params.value);
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Transport Mode', field: 'ib_actual_visit_mode',
                hide: props.reference.type === 'release',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Outbound Transport Mode', field: 'ob_actual_visit_mode',
                hide: props.reference.type === 'return',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                //floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Carrier', field: 'inbound_carrier_id',
                hide: props.reference.type === 'release',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Transport ID', field: 'ib_actual_visit',
                hide: props.reference.type === 'release',
                cellEditor: GridTextEditor
            },
            {
                headerName: 'Outbound Carrier', field: 'outbound_carrier_id',
                hide: props.reference.type === 'return',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Outbound Transport ID', field: 'ob_actual_visit',
                hide: props.reference.type === 'return',
                cellEditor: GridTextEditor,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Age', field: 'age',
                hide: props.reference.type === 'return',
                editable: false,
                width: 100,
                valueFormatter: function (params) {
                    if (params.data.construction_year) {
                        //return diffrence in years and months bettween today and construction_year
                        let today = dayJs();
                        let construction_year = dayJs(params.data.construction_year);
                        let years = today.diff(construction_year, 'year');
                        construction_year.add(years, 'years');
                        let months = today.diff(construction_year, 'months');
                        if (years || months) {
                            return years + 'y ' + months + 'm';
                        }
                        return '?';
                    } else {
                        return '';
                    }
                },
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
          
            {
                headerName: 'Days in Depot', field: 'days_in',
                hide: props.reference.type === 'return',
                editable: false,
                width: 100,
                valueFormatter: function (params) {
                    if (params.data.gate_in_date) {
                        return dayJs().diff(dayJs(params.data.gate_in_date), 'days');
                    } else {
                        return '';
                    }
                },
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

            {
                headerName: 'Color', field: 'color', 
                hide: false,
                width: 100,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

            {
                headerName: 'Floor', field: 'floor',
                hide: (props.reference.survey_size_type_id && props.reference.survey_size_type_id[2] === 'R'),
                width: 130,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: '', field: 'temperature',
                hide: (props.reference.type === 'return' || (props.reference.survey_size_type_id && props.reference.survey_size_type_id[2] !== 'R')),
                width: 100,
                cellEditor: GridNumberEditor,
                cellEditorParams: {
                    type: 'float',
                    integerLength: 2,
                    decimalLength: 1
                },
                headerComponentParams: {template: '<span><i large class="mdi-thermometer mdi" style="font-size: 1.5rem"></em>'},
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Temperature',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: "^[-+]$|^[-+]?\\d{1,2}\\.\\d{0,1}?$"
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {
                headerName: 'Temperature', field: 'temperature_type',
                // hide: (props.reference.type === 'return' || (props.reference.survey_size_type_id && props.reference.survey_size_type_id[2] !== 'R')),
                hide: true,
                headerComponentParams: { template: '<span><i large class="mdi-thermometer mdi" style="font-size: 1.5rem"></em>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Temperature',
                cellEditor: GridSelectAndTextEditor,
                cellEditorParams: {
                    valueList: [],
                    select: 'temperature_type',
                    text: 'temperature',
                    textSymbol: '°',
                    selectWithText: 'Exact'
                },
                valueFormatter: temperatureTypeAndTemperature,
                valueParser: common.lookupKey,
                valueGetter: (params) => {
                    return { temperature_type: params.data.temperature_type, temperature: params.data.temperature }
                },
                valueSetter: (params) => {
                    params.data.temperature_type = params.newValue.select;
                    params.data.temperature = params.newValue.text;
                    return true;
                }
            },

            {
                headerName: '', field: 'humidity',
                hide: (props.reference.type === 'return' || (props.reference.survey_size_type_id && props.reference.survey_size_type_id[2] !== 'R')),
                width: 100,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-water-percent mdi" style="font-size: 1.5rem"></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Humidity',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {
                headerName: '', field: 'ventilation',
                hide: (props.reference.type === 'return' || (props.reference.survey_size_type_id && props.reference.survey_size_type_id[2] !== 'R')),
                width: 100,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-fan mdi" style="font-size: 1.5rem"-procent></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Ventilation',
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {
                headerName: 'Redeliverer', field: 'returning_company_name',
                hide: true,
                cellEditor: GridTextEditor
                /*cellEditor: GridSelectEditorNeo,
                 cellEditorParams: { values: [] },
                 valueFormatter: common.lookupName,
                 valueParser: common.lookupKey,*/
                //Filter
                /*filter: GridSetFilter,
                 filterParams: {},*/
                //Floating
                /*floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                 floatingFilterComponentParams: {},*/
            },

            {
                headerName: 'Last On-Hire', field: 'last_on_hire_date',
                hide: true,
                editable: false,
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTime(params.value);
                },
            },

            {
                headerName: 'Insurance', field: 'insurance_type',
                hide: true,
                cellEditor: GridSelectAndTextEditor,
                cellEditorParams: { valueList: [] },
                //cellRenderer: insuranceAndInsuranceAmount,
                valueFormatter: insuranceAndInsuranceAmount,
                valueParser: common.lookupKey,
                valueGetter: (params) => {
                    return { insurance_type: params.data.insurance_type, insurance_amount: params.data.insurance_amount }
                },
                valueSetter: (params) => {
                    params.data.insurance_type = params.newValue.insurance_type;
                    params.data.insurance_amount = params.newValue.insurance_amount;
                    return true;
                },
                //Filter
                filter: GridToggleFilter,
                filterParams: {
                    allOption: true,
                    combinedOptions: [
                        {
                            combinedValue: 'yes',
                            values: ['amount', 'full'],
                            icon: 'mdi-currency-eur'
                        },
                        {
                            combinedValue: 'no',
                            values: ['none'],
                            icon: 'mdi-currency-eur-off'
                        }
                    ]
                },
                //Floating
                floatingFilterComponent: GridToggleFloatingFilterYesNo,
                floatingFilterComponentParams: {},
                floatingCellFormatter: common.lookupName,
                //cellEditorPopupPosition: 'under'
            },

            {
                headerName: 'Insurance Amount', field: 'insurance_amount',
                hide: true,
                editable: false,
                cellEditor: GridNumberEditor,
                //filter: 'agNumberColumnFilter',
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },

            {
                headerName: 'Specific Reference Comment', field: 'reference_comment',
                cellEditor: GridTextEditor,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

            {
                headerName: 'Last Modified', field: 'updated_at',
                editable: false,
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTime(params.value);
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {}                
            },

            /*{headerName: '', field: 'actions', pinned: 'right', filter: false, width: 40, cellStyle: { 'padding-left': 0, 'padding-right': 0 }, editable: false, resizable: false,
             cellRenderer: '',
             cellRendererParams: {
             clicked: (field)=>{ }
             }
             }*/
            {
                headerName: 'User', field: 'updated_by',
                flex: 1,
                minWidth: 150,
                resizable: false,
                suppressSizeToFit: true,
                editable: false,
                cellEditor: GridTextEditor,
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },                
            },
            /*{headerName: '', field: '',
             flex: 1,
             editable: false,
             sortable: false,
             filter: false,
             suppressMovable: true,
             resizable: false
             },*/
        ]


        if (props.reference.type=='release') {
            columnDefs.push(...[
                {
                    headerName: 'Repair Criteria', 
                    field: 'repair_criteria',
                    //Editor
                    cellEditor: 'agTextCellEditor',
                    //Filter
                    filter: GridTextFilter,
                    filterParams: { },
                    //Floating
                    floatingFilterComponent: GridTextFloatingFilter,
                    floatingFilterComponentParams: {}
                },
                {
                    headerName: 'Sale Grade', 
                    field: 'sale_grade',
                    editable: false,
                    //Filter
                    filter: GridTextFilter,
                    filterParams: { },
                    //Floating
                    floatingFilterComponent: GridTextFloatingFilter,
                    floatingFilterComponentParams: {}
                },
                {
                    headerName: 'Cargo Condition', field: 'cargo_condition_id',
                    editable: false,
                    //minWidth: 170,
                    cellEditor: GridSelectEditorNeo,
                    cellEditorParams: {
                        valueList: [],
                        default: ReferenceListValidator.isRequired('cargo_condition_id') ? 'first' : false
                    },
                    valueFormatter: common.lookupName,
                    valueParser: common.lookupKey,
                    //Filter
                    filter: GridSelectWithIconFilter,
                    filterParams: function(params){
                        let options=[];
                        let item;
                        for (let i in params.colDef.cellEditorParams.valueList){
                            item = params.colDef.cellEditorParams.valueList[i];
                            options.push({
                                id: item.code,
                                name: item.name                            
                            });
                        }
                        return {
                            options: options
                        }
                    },
                    //Floating
                    floatingFilter: true,
                    floatingFilterComponent: GridSelectFloatingFilter,
                    floatingFilterComponentParams: {}
                },
            ]);
        }

        if (props.reference.survey_size_type_id[2] === 'R')
        {
            columnDefs.push(...[
                {headerName: 'Machinery Manufacturer', field: 'machinery_manufacturer',
                    hide: (props.reference.type === 'return'),
                    editable: false,
                },
            ]);
        }
        const columnOrder = props.reference.type=='return' ?
            [
                'new',
                'valid',
                'attributes',
                'id',
                'stock_container_status_id',
                'unit_number',
                'inspection_criteria',
                'dpp',
                'condition',
                'run_state',
                'repair_criteria',
                'sale_grade',
                'cargo_condition_id',
                'gate_in_date',
                'gate_out_date',
                'ib_actual_visit_mode',
                'ob_actual_visit_mode',
                'inbound_carrier_id',
                'ib_actual_visit',
                'outbound_carrier_id',
                'ob_actual_visit',
                'age',
                'days_in',
                'color',
                'floor',
                'machinery_manufacturer',
                'temperature',
                'temperature_type',
                'humidity',
                'ventilation',
                'returning_company_name',
                'last_on_hire_date',
                'insurance_type',
                'insurance_amount',
                'reference_comment',
                'updated_at',
                'updated_by'            
            ] : 
            [
                'new',
                'valid',
                'attributes',
                'id',
                'stock_container_status_id',
                'unit_number',
                'dpp',
                'condition',
                'run_state',
                'repair_criteria',
                'sale_grade',
                'cargo_condition_id',
                'gate_in_date',
                'gate_out_date',
                'ib_actual_visit_mode',
                'ob_actual_visit_mode',
                'inbound_carrier_id',
                'ib_actual_visit',
                'outbound_carrier_id',
                'ob_actual_visit',
                'age',
                'days_in',
                'color',
                'floor',
                'machinery_manufacturer',
                'temperature',
                'temperature_type',
                'humidity',
                'ventilation',
                'returning_company_name',
                'last_on_hire_date',
                'insurance_type',
                'insurance_amount',
                'reference_comment',
                'updated_at',
                'updated_by'            
                ];
        return [
            ...common.columnDefs(),
            ...columnDefs.sort((a, b) => {
                const indexA = columnOrder.indexOf(a.field);
                const indexB = columnOrder.indexOf(b.field);
                const rankA = indexA === -1 ? 1000 : indexA;
                const rankB = indexB === -1 ? 1000 : indexB;
                return rankA - rankB;
              }),
        ]

    }

    return {
        getContainerReferenceListDefinition
    };

}
import { nextTick, onMounted, onBeforeUnmount, toRaw } from "vue";
import GridTooltip from '@/components/aggrid/GridTooltip'
import DataLoading from '@/components/ui/DataLoading'
import NoData from '@/components/ui/NoData'
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from 'ag-grid-vue3';
import { Papa } from 'papaparse';
import _ from 'lodash';



//COMPOSABLES
const { advancedAxios } = useAdvancedAxios();



//VARIABLES
let uniqueId = 1;


export function useCommon(gridRef, state, gridApi, columnApi, data) {

    const gridOptions = {
        enableCellTextSelection:true,
        ensureDomOrder:true,
        pagination: false,
        rowSelection: 'multiple',
        stopEditingWhenCellsLoseFocus: false,
        enableCellChangeFlash: false,
        suppressAutoSize: false,
        suppressColumnVirtualisation: true,
        skipHeaderOnAutoSize: false,
        suppressClickEdit: true,
        tooltipShowDelay: 500,
        tooltipHideDelay: 9999999,
        getRowNodeId: params => {
            return params.data.uniqueId
            //return params.data.id ? params.data.id : params.data.uniqueId
        },
        getRowId: params => {
            return params.data.uniqueId
            //return params.data.id ? params.data.id : params.data.uniqueId
        },
        rowClassRules: {
            "unvalidated": params => !params.data.valid,
        },
        loadingOverlayComponent: DataLoading,
        loadingOverlayComponentParams: {
            type: 2
        },
        noRowsOverlayComponent: NoData
    }

    const defaultColDef = {
        resizable: true,
        editable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,        //To be set to true, hides filter in header
        suppressColumnVirtualisation: true,
        filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true //To be set to true, hides filter button in floating filter
        },
        tooltipComponent: GridTooltip,
        suppressKeyboardEvent: function (params) {
            let event = params.event;
            let key = event.key;
            let deniedKeys = ['Escape'];
            let suppress = params.editing && deniedKeys.indexOf(key) >= 0;
            if (event.key === 'Enter'/* && params.editing==true*/) {
                suppress = true;
            }
            return suppress;
        }
    }



    //EVENTS
    onMounted(() => {
        if (gridRef && gridRef.value && gridRef.value.$el) {
            gridRef.value.$el.querySelector('.ag-center-cols-viewport').addEventListener('mousedown', stopEditingOnClickBelowRows);
        }
    })
    onBeforeUnmount(() => {
        if (gridRef && gridRef.value && gridRef.value.$el) {
            gridRef.value.$el.querySelector('.ag-center-cols-viewport').removeEventListener('mousedown', stopEditingOnClickBelowRows);
        }
    })



    //METHODS
    const columnDefs = () => {
        return [
            {   
                headerName: 'Valid', field: 'valid',
                hide: true
            }
        ];
    }
    
    const stopEditingOnClickBelowRows = (event) => {
        if (event.target === event.currentTarget) {
            stopEditingOnRightClick(state, gridApi.value);
        }
    }
    const lookupName = (params) => {
        let valueList = (typeof params.colDef.cellEditorParams === 'function') ? params.colDef.cellEditorParams(params).valueList : params.colDef.cellEditorParams.valueList;
        let res = valueList.find(item => item.id == params.value);
        return res ? res.name : null;
    };

    const lookupKey = (params) => {
        let valueList = (typeof params.colDef.cellEditorParams === 'function') ? params.colDef.cellEditorParams(params).valueList : params.colDef.cellEditorParams.valueList;
        let res = valueList.find(item => item.name === params.value);
        return res ? res['name'] : null;
    };

    const reloadHandler = (state, data, populateGridWithData) => {
        state.isEditing = 'no';
        data.rowData = [];
        data.pinnedTopRowData = [];
        populateGridWithData();
    };

    const allFieldsExist = (response, fields, store) => {
        for (let index in fields) {
            if (response.data.models[index].length > 0) {
                fields[index].forEach((field) => {
                    if (!(field in response.data.models[index][0])) {
                        store.addNotification('Data received from server does not contain expected field: ' + index + ':' + field, 'error');
                        return false;
                    }
                });
            }
        }
        return true;
    };

    const setupGrid = (response, modelSubmodelMap, columnDefs, prepareRecord, prepareListRecord, data) => {
        let pointer = null;
        let submodel = null;
        let list = [];

        for (let index in modelSubmodelMap.submodels) {
            if (response.data.submodels[index]) {
                submodel = response.data.submodels[index];
                pointer = columnDefs.value.find(def => def.field === index);
                if (pointer) {
                    list = [];
                    //When we have an array of objects
                    submodel.forEach(sub => {
                        list.push(prepareListRecord(sub, modelSubmodelMap.submodels[index]));
                    });
                    if (('unselectedOption' in modelSubmodelMap.submodels[index]) && modelSubmodelMap.submodels[index].unselectedOption === true) {
                        list.push({ id: null, name: 'Unspecified' });
                    }

                    pointer.cellEditorParams.valueList = list;
                }
            }
        }

        let newList = [];
        response.data.models[Object.keys(modelSubmodelMap.model)[0]].map(item => {
            newList.push(prepareRecord(item));
        });
        data.rowData = newList;
    };

    const normalServerReload = (url, axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, prepareListRecord, data, onSuccess, onError, onBeforeSetRowData = null) => {
        setTimeout(function () {
            axios.get(url).then(function (response) {
                if (allFieldsExist(response, fields, store)) {
                    if (onBeforeSetRowData !== null){
                        onBeforeSetRowData(response);
                    }
                    setupGrid(response, modelSubmodelMap, columnDefs, prepareRecord, prepareListRecord, data);

                    if (typeof onSuccess === 'function') {
                        onSuccess(response);
                    }
                    if (gridApi2.value){
                        gridApi2.value.hideOverlay();
                    }
                }
            }).catch(function (error) {
                if (typeof onError === 'function') {
                    onError(error.message);
                }
                if (gridApi2.value){
                    gridApi2.value.hideOverlay();
                }
            });
        }, 1000);
    };

    const stopEditingOnRightClick = (state, gridApi) => {
        if (state.isEditing !== 'no') {
            gridApi.stopEditing();
        }
    };

    const unpinRows = (txt, data, gridApi, store) => {
        let movedRows = [];
        for (let item of data.pinnedTopRowData) {
            if (item['id'] !== '') {
                movedRows.push(item.uniqueId);
                data.rowData.push(item);
            }
        }
        for (let item of movedRows) {
            let delIndex = data.pinnedTopRowData.findIndex(o => o.uniqueId === item.uniqueId);
            if (delIndex){
                data.pinnedTopRowData.splice(delIndex, 1);
            }
        }
        gridApi.setRowData(data.rowData);
        if (data.pinnedTopRowData.length > 0) {
            store.addNotificationMessage('Some ' + txt + ' were not moved to the main list since they are not saved yet.', 'warning');
        }
    };

    const prepareListRecord = (item, sub) => {
        let idString = sub.id ? sub.id : 'id';
        let nameString = sub.name ? sub.name : 'name';

        let theName = null;
        if (Array.isArray(nameString)) {
            theName = getCombinedNames(item, nameString);
        } else {
            theName = item[nameString];
        }
        return { id: item[idString], name: theName };
    };

    const getCombinedNames = (value, path) => {
        let theName = '';
        let miniPath = [];
        path.forEach(pt => {
            miniPath = pt.split('.');
            if (theName !== '') {
                theName += ' - ';
            }
            theName += (miniPath.length === 1 ? value[miniPath] : getDeepAttribute(value, miniPath));
        });
        return theName;
    };

    const getDeepAttribute = (value, path) => {
        if (path.length === 1) {
            return value[path];
        } else {
            return getDeepAttribute(value[path[0]], path.shift() ? path : null);
        }
    };

    const getNodeId = (data) => {
        return data.id;
    };

    const onKeyPressHandler = (event, gridApi, data, state) => {
        if (event.key === 'Escape') {
            deleteEditingRow(gridApi, data, state);
        }
        if (event.key === 'Enter') {
            if (event.ctrlKey === true) {
                gridApi.stopEditing();
            } else {
                let focusedCell = gridApi.getFocusedCell();
                if (focusedCell) {
                    gridApi.setFocusedCell(focusedCell.rowIndex, focusedCell.column.colId, focusedCell.rowPinned);
                    gridApi.startEditingCell({
                        rowIndex: focusedCell.rowIndex,
                        colKey: focusedCell.column.colId,
                        rowPinned: focusedCell.rowPinned
                    });
                    if (document.activeElement.firstChild) {
                        document.activeElement.firstChild.focus();
                    }
                }
            }
        }
    }

    const deleteEditingRow = (gridApi, data, state) => {
        let editingCells = gridApi.getEditingCells();
        if (editingCells && editingCells.length > 0) {
            let editingRowIndex = editingCells[0].rowIndex;
            let editingCellsPinned = editingCells[0].rowPinned;
            if (data.rowData[editingRowIndex].id === "") {
                data.rowData.splice(editingRowIndex, 1);
                gridApi.setRowData(data.rowData);
            }
            nextTick(() => {
                gridApi.stopEditing(true);
                state.isEditing = 'no';
            });
            return true;
        } else {
            return !gridApi.getSelectedNodes().length > 0;
        }
    }

    const isTopRowEditing = (gridApi, data, state) => {
        let editingCells = gridApi.getEditingCells();
        if (editingCells && editingCells.length > 0) {
            let editingRowIndex = editingCells[0].rowIndex;
            let editingCellsPinned = editingCells[0].rowPinned;
            return (data.rowData[editingRowIndex].id === "") ? true : false;
        } else {
            return false;
        }
    }



    //GRID METHODS
    const tabToNextCell = (params, gridApi) => {
        let result = null;

        let nextCellEditable = false;
        if (params.nextCellPosition) {
            //I had to build the params here because there was no other source to get the rowData
            let constructedParams = gridApi.getDisplayedRowAtIndex(params.nextCellPosition.rowIndex);
            nextCellEditable = typeof params.nextCellPosition.column.colDef.editable === 'function' ? params.nextCellPosition.column.colDef.editable(constructedParams) : params.nextCellPosition.column.colDef.editable;
        }

        if (!nextCellEditable) {
            setTimeout(function () { gridApi.tabToNextCell(); }, 1);
            return params.nextCellPosition;
        }

        if (params.nextCellPosition && nextCellEditable) {
            gridApi.setFocusedCell(params.nextCellPosition.rowIndex, params.nextCellPosition.column.colId, params.nextCellPosition.rowPinned);
            let ins = gridApi.getCellEditorInstances({ columns: [params.nextCellPosition.column.colId] });
            if (Array.isArray(ins) && ins.length > 0) {
                ins = ins[0];
                if (ins.activateCellEditor) {
                    ins.activateCellEditor();
                }
            }

            result = params.nextCellPosition;
        }

        return result;
    };

    const deactivateAllCellEditors = (gridApi) => {
        let ins = gridApi.getCellEditorInstances();
        let max=ins.length;
        for (let i=0;i<max;i++){
            if (ins[i].focusOut){
                ins[i].focusOut();
            }
        }
    }

    const onCellMouseDown = (params) => {
        params.event.preventDefault();
    }

    const copyValue = (gridApi, data) => {
        let focusedCell = gridApi.getFocusedCell();
        if (focusedCell) {
            let row = gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
            let newValue = row.data[focusedCell.column.colId];
            let newRowData = [...data.rowData];
            let max = newRowData.length;
            for (let i = 0; i < max; i++) {
                newRowData[i][focusedCell.column.colId] = newValue;
            }
            data.rowData = newRowData;
        }
    }


    const onCellContextMenu = (params, gridApi, state) => {
        if (params.event.shiftKey==true){
            gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn, rowPinned: params.rowPinned });
            if (params.rowPinned === undefined) {
                state.isEditing = 'existing';
            } else {
                state.isEditing = params.rowPinned;
            }
        }
    }

    const onCellClicked = (params, store) => {
        if (params.event.ctrlKey==true){
            let text = params.value;
            if (params.column.colDef.valueFormatter){
                let obj = {
                    colDef: params.column.colDef,
                    value: text
                }
                text = params.column.colDef.valueFormatter(obj);
            }
            try {
                navigator.clipboard.writeText(text);
                store.addNotificationMessage('Value copied', 'info', 'fast');
            } catch (err) {
                store.addNotificationMessage('Value could not be copied', 'error');
            }
            params.event.preventDefault();
        }
    }

    const onGridReady = (params, definition, columnDefs, state, populateGridWithData) => {
        let theDefinition = definition();
        columnDefs.value = theDefinition;
        for (let item of columnDefs.value) {
            if (!item.hide || (item.hide && !item.hide)) {
                state.firstVisibleColumn = item.field;
                break;
            }
        }
        populateGridWithData();
        styleHeaders(columnDefs, theDefinition);
    }

    const onRowDataChanged = (columnApi) => {
    }

    const onCellValueChanged = (params, columnApi) => {
        columnApi.autoSizeColumns([params.column.getColId()], false/*Skip headers*/);
    }

    const onRowEditingStarted = (params, state, prefixId) => {
        advancedAxios.cancelRequest(prefixId + (params.data.id ? params.data.id : params.data.uniqueId));
        if (params.rowPinned === 'top') {
            state.isEditing = 'pinned';
        }
        if (params.rowPinned === undefined) {
            state.isEditing = 'existing';
        }
        state.editedRow = {...toRaw(params.data)};
    }

    const onRowDoubleClicked = (params, state, gridApi) => {
        if (params.rowPinned=='top' && params.data.id==''){
            gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn, rowPinned: 'top' });
        }
    }

    const prepareRecord = (item) => {
        //If uniqueId is alrady set then don't change it
        return !item.uniqueId ? { uniqueId: uniqueId++, new: (item.new ? item.new : 0) } : { new: (item.new ? item.new : 0) };
    }

    const styleHeaders = (columnDefs, definition) => {
        for (let item of columnDefs.value) {
            if (item.custom && item.custom.required) {
                item.headerClass = 'ag-required-header';
            }
        }
    }
    const onSortChanged = (e) => {
        var colState = columnApi.value.columnModel.getColumnState();
        let columnNewIndex = colState.findIndex(o => o.colId === 'new');
        colState[columnNewIndex].sort = 'desc';
        colState[columnNewIndex].sortIndex = 0;
        columnApi.value.columnModel.applyColumnState({
            state: colState
        });
        data.rowData.forEach(item => {
            item.new = 0;
        });
        gridApi.value.setRowData(data.rowData);
    }




    //COMPUTED
    const allowReload = (data, gridApi) => {
        let allow = false;
        if (state.gridReady === true && gridApi) {
            allow = true;
        }
        return allow;
    }
    const allowAdd = (data, gridApi) => {
        let allow = false;
        if (state.gridReady === true && gridApi) {
            allow = true;
        }
        return allow;
    }
    const allowEdit = (data, gridApi) => {
        let allow = false;
        if (state.gridReady === true && gridApi && state.isEditing === 'no' && gridApi.getSelectedNodes().length == 1) {
            allow = true;
        }
        return allow;
    }
    const allowDelete = (data, gridApi) => {
        let allow = false;
        if (state.gridReady === true && gridApi) {
            if (gridApi.getSelectedNodes().length > 0) {
                allow = true;
            }
            /*if (isTopRowEditing(gridApi, data, state)) {
                allow = true;
            }*/
        }
        return allow;
    }

    const getFirstVisibleColumn = (columnDefs) => {
        for (const item of columnDefs.value) {
            if (!item.hide || (item.hide && !item.hide)) {
                state.firstVisibleColumn = item.field;
                break;
            }
        }
    }

    const exportCsv = (gridApi, customParams, extraParams) => {
        let params = {
            fileName: 'export.csv',
            columnSeparator: ';',
            processCellCallback: function (cell) {
                let returnValue = cell.value;
                let extraReturnValue = false;
                if (extraParams.processCellCallback) {
                    extraReturnValue = extraParams.processCellCallback(cell);
                }
                if (extraReturnValue !== false) {
                    returnValue = extraReturnValue;
                }
                if (extraReturnValue === false && cell.column.colDef.valueFormatter) {
                    returnValue = cell.column.colDef.valueFormatter({
                        value: cell.value,
                        data: cell.node.data,
                        colDef: cell.column.colDef
                    });
                }
                return returnValue;
            }
        };

        gridApi.exportDataAsCsv({ ...params, ...customParams });
    }

    const updateChangesFromBackendOnUpdatingRows = (rows, gridApi, data, prepareRecord) =>{
        let found;
        let model;
        let rowNode;
        if (!Array.isArray(rows)){
            rows = [rows];
        }
        rows.map(item => {
            found = false;
            model=prepareRecord(item);

            data['rowData'].find((o,i) => {
                //We only check for uniqueId because the id can be changed in the backend
                //if ((o.id!=='' && o.id === item.id) || (o.id==='' && o.uniqueId === item.uniqueId)){
                if (o.uniqueId === item.uniqueId){
                    rowNode = gridApi.getRowNode(o.uniqueId);
                    found = true;
                    model.valid = true;
                    data['rowData'][i] = {...data['rowData'][i], ...model};
                    rowNode.setData(data['rowData'][i]);
                    return true;
                }
            });
            //params.node.setData(model);
            //params.data.valid=true;
            if (!found){
                data['pinnedTopRowData'].forEach((element, index) => {
                    rowNode = gridApi.getPinnedTopRow(index);
                    //if ((rowNode.data.id!=='' && rowNode.data.id === item.id) || (rowNode.data.id==='' && rowNode.data.uniqueId === item.uniqueId)){
                        if (rowNode.data.uniqueId === item.uniqueId){
                        found = true;
                        model.valid = true;
                        data['pinnedTopRowData'][index] =  {...data['pinnedTopRowData'][index], ...model};
                        rowNode.setData(data['pinnedTopRowData'][index]);
                    }
                });
            }
        });

    }

    const editedRowChanged = (state, newRow) => {
        /*console.log("-----------------");
        console.log(toRaw(state.editedRow));
        console.log(toRaw(newRow));*/
        return !(_.isEqual(toRaw(state.editedRow), toRaw(newRow)));
    }

    const onFilterChanged = (gridApi) => {
        gridApi.stopEditing();
    }

    return {
        common: {
            //DATA
            gridOptions,
            defaultColDef,
            //instance: readonly(instance),
            //METHODS
            lookupName,
            lookupKey,
            reloadHandler,
            allFieldsExist,
            setupGrid,
            normalServerReload,
            stopEditingOnRightClick,
            unpinRows,
            prepareListRecord,
            getCombinedNames,
            getDeepAttribute,
            copyValue,
            getNodeId,
            onKeyPressHandler,
            //GRID METHODS
            tabToNextCell,
            deactivateAllCellEditors,
            onCellMouseDown,
            onCellContextMenu,
            onGridReady,
            onRowDataChanged,
            onCellValueChanged,
            onRowEditingStarted,
            onRowDoubleClicked,
            prepareRecord,
            deleteEditingRow,
            isTopRowEditing,
            styleHeaders,
            //COMPUTED
            allowAdd,
            allowDelete,
            allowEdit,
            getFirstVisibleColumn,
            exportCsv,
            updateChangesFromBackendOnUpdatingRows,
            onFilterChanged,
            onSortChanged,
            onCellClicked,
            columnDefs,
            editedRowChanged
        }
    };

}
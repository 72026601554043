<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar>
                <v-btn icon="mdi-arrow-left" size="x-large" variant="text" @click="backButton"></v-btn>
                <v-btn :icon="showFilter ? 'mdi-filter-off' : 'mdi-filter'" size="x-large" variant="text"
                    @click="toggleFilter"></v-btn>
            </v-toolbar>
            <v-toolbar>
                <v-toolbar-title class="flex text-center">
                    <h3>SELECT</h3> CONTAINER
                </v-toolbar-title>
            </v-toolbar>
            <v-toolbar>
            </v-toolbar>
        </div>
        <div v-if="taskData.task" class="main-content-info">
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Reference</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__reference ? taskData.task.task__reference.reference :
                        '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Licence Plate</strong></v-list-item-title>
                <v-list-item-subtitle>{{ taskData.task.data }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Carrier Name</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__carrier ? taskData.task.task__carrier.name : '&nbsp;'
                    }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Size Type</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__reference ?
                        taskData.task.task__reference.survey_size_type_id : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Customer</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__reference ?
                        taskData.task.task__reference.operator__company.name : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Container Condition</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__reference ?
                        taskData.task.task__reference.cargo_condition_id : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Freight Kind</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    taskData.task.task__reference ? taskData.task.task__reference.freight_kind
                        : ''
                }}</v-list-item-subtitle>
            </v-list-item>
        </div>
        <div class="main-content-playground">

            <div style="width: auto;height: 100%; text-align: center; margin: auto;">
                <ag-grid-vue id="stockGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine"
                    :columnDefs="columnDefs" :defaultColDef="defaultColDef" :components="components" :rowData="data.rowData"
                    :gridOptions="gridOptions" :rowHeight="70" :headerHeight="70" :floatingFiltersHeight="70">
                </ag-grid-vue>
            </div>

            <div v-show="showFilter == true"
                style="position: absolute;top:0;right:0;width:50%;height:100%;border-left:1px solid #babfc7">
                <KeypadInput :backdrop=false connect="parent" :show="showFilter" :all="keypadAllCharacters"
                    :allowed="keypadAllowedList" :characters="4" :characters_from="'end'" :key_layout="'normal'"
                    :done_button=true :input_selection=true @update="filterUpdate" @done="toggleFilter" />
            </div>

        </div>

        <div class="main-content-footer">
            <AppMessageNotifications :height=70 :font=16 />
        </div>
    </div>
</template>

<script setup>



//IMPORTS
import { inject, ref, reactive, defineProps, computed } from 'vue';
import { useAppStore } from '@/stores/app';
import { useYamaxStockListDefinition } from '@/components/aggrid/definitions/useYamaxStockListDefinition';
import DataLoading from '@/components/ui/DataLoading';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { useRouter } from 'vue-router';
import { useTransporter } from '@/composables/useTransporter';
import { AgGridVue } from "ag-grid-vue3";
import _ from 'lodash';
import KeypadInput from '@/components/ui/KeypadInput';



//REFERENCES
const stockGrid = ref(null);



//INJECTIONS
const axios = inject('axios');
const dayJs = inject("dayJs")
const router = useRouter();
let taskData = reactive({ task: {} });


//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;



//CONSTANTS
const fields = {
    'stockContainers': [
        'id', 'unit_number', 'iso_code', 'construction_year', 'depot_id', 'matched_shipping_line', 'matched_leasing_company', 'gate_in_date', 'turn_in_reference',
        'ib_actual_visit_mode', 'inbound_carrier_id', 'ib_actual_visit', 'gate_out_date', 'booking_number', 'ob_actual_visit_mode', 'outbound_carrier_id',
        'ob_actual_visit', 'color', 'floor', 'temperature', 'humidity', 'ventilation', 'comment', 'crun_id', 'created_at', 'updated_at', 'system',
        'position', 'condition'
    ]
};
const store = useAppStore();
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
})
const state = reactive({})
const showFilter = ref(false);
const filterValue = ref('');
const keypadAllCharacters = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);



//COMPUTED
const keypadAllowedList = computed(() => {
    if (data.rowData == null) {
        return [];
    }
    let max = data.rowData.length;
    let list = [];
    for (let i = 0; i < max; i++) {
        list.push(data.rowData[i].unit_number);
    }
    return list;
})

const containerCount = computed(() => {
    return data.rowData ? data.rowData.length : 0;
})



//COMPOSABLES
const { transporter } = useTransporter();



//PROPS
const props = defineProps({
    task_id: {
        type: String,
        required: true,
        default: null,
        hover: false,
    },
    transportId: {
        type: String,
        required: true,
        default: null,
        hover: false,
    },
})



//COMPOSABLES
const { common } = useCommon(stockGrid, state, gridApi2);
const { getYamaxStockListDefinition } = useYamaxStockListDefinition(store, dayJs);
//



const columnDefs = ref([])
const defaultColDef = reactive({
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,        //To be set to true, hides filter in header
    filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true
    },
    floatingFilterComponentParams: {
        suppressFilterButton: true //To be set to true, hides filter button in floating filter
    },
})

const gridOptions = /*reactive(*/{
    pagination: false,

    loadingOverlayComponent: DataLoading,
    loadingOverlayComponentParams: {
        type: 2
    },

    isExternalFilterPresent() {
        return filterValue.value.length > 0;
    },
    doesExternalFilterPass(node) {
        let len = filterValue.value.length;
        if (len < 4) {
            return node.data.unit_number.slice(-4, len - 4) == filterValue.value;
        } else {
            return node.data.unit_number.slice(-4) == filterValue.value;
        }
    },

    onRowDataChanged: (params) => {
        params.api.sizeColumnsToFit();
    },

    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        common.onGridReady(props.params, getYamaxStockListDefinition, columnDefs, state, populateGridWithData);
    },
    onRowClicked: (params) => {
        transporter.setData(props.transportId, params.data);
        // save selected container to task
        if (props.task_id != 'internal' && props.task_id != 'tgin' && props.task_id != 'tgout') {

            let url = '/api/yamaxTasks/' + props.task_id;

            let sendData = {
                "id": props.task_id,
                "status": "in_progress",
                "container_id": params.data.id
            }

            axios({ method: 'put', url: url, data: sendData }).then(function (response) {
                router.push({ name: 'TaskRenderer', params: { id: props.task_id } });
            })
        } else {
            console.log(props);
            if (props.task_id == 'tgin') {
                router.push({ name: 'TgAppGateIn', params: { id: props.task_id } })
            }
            if (props.task_id == 'tgout') {
                router.push({ name: 'TgAppGateIn', params: { id: props.task_id } })
            }
            else {
                router.push({ name: 'InternalRenderer' });
            }
        }

    },

}



//EVENTS




//METHODS
const onKeyPressHandler = (event) => {
    if (event.shiftKey) {
        if (event.key == '+') {
            //
        }
    } else {
        common.onKeyPressHandler(event, gridApi, data, state);
    }
}

const populateGridWithData = () => {
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });
    const onServerReloadSuccess = () => {
        columnApi.autoSizeAllColumns();
        toggleFilter();
    }
    if (props.task_id != 'internal' && props.task_id != 'tgin' && props.task_id != 'tgout') {
        axios.get('/api/yamaxTasks/' + props.task_id).then(function (response) {
            taskData.task = response.data.task;
            common.normalServerReload(`/api/yamax?reference=${taskData.task.task__reference.id}
            &facility=${taskData.task.facility_id}
            &iso_code=${taskData.task.task__reference.survey_size_type_id}
            &matched_shipping_line=${taskData.task.task__reference.operator_id}
            &freight_kind=${taskData.task.task__reference.freight_kind}
            &status=In Stock&ready=true`, axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onServerReloadSuccess);
        })
    }
    else {
        taskData.task = null;
        common.normalServerReload('/api/yamax?status=In Stock', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onServerReloadSuccess);
    }
}

const toggleFilter = () => {
    showFilter.value = !showFilter.value;
}

const filterUpdate = (data) => {
    filterValue.value = data.value;
    if (gridApi) {
        gridApi.onFilterChanged();
    }
}

const prepareRecord = (item) => {
    return {
        ...common.prepareRecord(item)
        ,
        ...{
            valid: true,
            id: item.id || '',
            crun_id: item.id || '',
            system: item.system || '',
            unit_number: item.unit_number || '',
            depot_id: item.depot_id || '',
            matched_leasing_company: item.matched_leasing_company || null,
            matched_shipping_line: item.matched_shipping_line || null,
            operator_name: item.operator_name || '',
            iso_code: item.iso_code || '',
            gate_in_date: item.gate_in_date || '',
            gate_out_date: item.gate_out_date || '',
            position: item.position || '',
            construction_year: item.report__report ? item.report__report.construction_year : item.construction_year,
            ib_actual_visit_mode: item.ib_actual_visit_mode || '',
            ib_actual_visit: item.ib_actual_visit || '',
            ob_actual_visit_mode: item.ob_actual_visit_mode || '',
            ob_actual_visit: item.ob_actual_visit || '',
            condition: item.condition || '',
            last_on_hire_date: item.last_on_hire_date || '',
            comment: item.comment || '',
            color: item.color || '',
            floor: item.floor || '',
            temperature: item.temperature || '',
            humidity: item.humidity || '',
            ventilation: item.ventilation || '',
            created_at: item.created_at || '',
            updated_at: item.updated_at || '',
            depot__company: item.depot__company,
            shipping_line__company: item.shipping_line__company,
            inbound_carrier_id: item.inbound_carrier_id || '',
            outbound_carrier_id: item.outbound_carrier_id || '',
            returning_company_name: item.returning_company_name,
            report_report: item.report__report || '',
            positions: item.stock_container__positions || '',
            cleaning_status: getCleaningStatus(item.report__report),
        }
    }
}


// function to get the cleaning status
const getCleaningStatus = (report) => {
    if (!report) {
        return '';
    }
    if (report.estimate_item__estimate_item) {
        let cleaning = [];
        report.estimate_item__estimate_item.forEach(item => {
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'SC') {
                cleaning.push('Steam Clean');
            }
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'AC') {
                cleaning.push('Blowout');
            }
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'MC') {
                cleaning.push('Special Clean');
            }
        });
        //make the elements from cleaning array unique
        cleaning = [...new Set(cleaning)];
        //make a string from the array with commas between the elements
        cleaning = cleaning.join(', ');

        return cleaning;
    } else {
        return '';
    }
}


const validateRecord = (params) => {
    let ret = {
        valid: true,
        message: ''
    };

    ret.valid = true;
    return ret;
}

const modelSubmodelMap = {
    model: {
        'stockContainers': { target: function () { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
        'matched_shipping_line': { target: function () { return columnDefs.value.find(def => def.field === 'matched_shipping_line') } },
        'depot_id': { target: function () { return columnDefs.value.find(def => def.field === 'depot_id') } },
        'matched_leasing_company': { target: function () { return columnDefs.value.find(def => def.field === 'matched_leasing_company') } },
        'ib_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ib_actual_visit_mode') } },
        'ob_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ob_actual_visit_mode') } },
        'iso_code': { id: 'code', name: ['code', 'survey_type__survey_type.name'], prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'iso_code').cellEditorParams.valueList } },
        'inbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'inbound_carrier_id').cellEditorParams.valueList } },
        'outbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'outbound_carrier_id').cellEditorParams.valueList } },
        'color': { target: function () { return columnDefs.value.find(def => def.field === 'color') } },
        'floor': { target: function () { return columnDefs.value.find(def => def.field === 'floor') } }
    }
};

const backButton = (params) => {
    if (props.task_id != 'internal') {
        router.push({ name: 'TaskRenderer', params: { id: props.task_id } })
    }
    else {
        if (props.task_id == 'tgin') {
            router.push({ name: 'TgAppGateIn', params: { id: props.task_id } })
        }
        if (props.task_id == 'tgout') {
            router.push({ name: 'TgAppGateIn', params: { id: props.task_id } })
        }
        else {
            router.push({ name: 'InternalRenderer' });
        }
    }
};

</script>

<script>
export default {
    name: 'YamaxStock',
    customOptions: {}
}
</script>
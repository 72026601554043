<template>
    <div class="main-content-area" ref="focusTarget">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-plus-thick" size="small" variant="text" :disabled="allowAdd == false"
                    @click="addNewContainer"></v-btn>
                <v-btn icon="mdi-pencil" size="small" variant="text" :disabled="allowEdit == false"
                    @click="editContainer"></v-btn>
                <v-btn icon="mdi-delete" size="small" variant="text" :disabled="allowDelete == false"
                    @click="deleteContainer"></v-btn>
                <v-btn icon="mdi-reload" size="small" variant="text" :disabled="allowAdd == false"
                    @click="common.reloadHandler(state, data, populateGridWithData)"></v-btn>
                <v-btn icon="mdi-check" size="small" variant="text" :disabled="state.isEditing == 'no'"
                    @click="finishEditingContainer"></v-btn>
                <v-btn icon="mdi-format-paint" size="small" variant="text" @click="copyValues"></v-btn>
                <v-btn icon="mdi-table-column-plus-after" size="small" variant="text"
                    @click="importCsv(gridApi, data)"></v-btn>
                <v-btn v-if="!state.isShowingInformation" icon="mdi-information-outline" size="small" variant="text"
                    @click="toggleShowInformation"></v-btn>
                <v-btn v-if="state.isShowingInformation" icon="mdi-information-off-outline" size="small" variant="text"
                    @click="toggleShowInformation"></v-btn>
                <v-btn v-if="props.reference.type == 'return'" 
                    icon="mdi-truck-delivery" 
                    size="small" 
                    variant="text" 
                    v-tippy="{ content: 'Gate-In Container' }"
                    :disabled="allowGateInOut == false"
                    @click="gateContainers('in')"></v-btn>
                <v-btn v-if="props.reference.type == 'release'" 
                    icon="mdi-truck-delivery" 
                    size="small" 
                    variant="text"
                    style="transform: scaleX(-1)"
                    v-tippy="{ content: 'Gate-Out Container' }"
                    :disabled="allowGateInOut == false"
                    @click="gateContainers('out')"></v-btn>
                <v-btn icon="mdi-step-backward" size="small" variant="text"
                    :disabled="allowRevert == false"
                    v-tippy="{ content: props.reference.type == 'return' ? 'Revert Gate-In' : 'Revert Gate-Out' }"
                    @click="revertGateInOut"></v-btn>
            </v-toolbar>
            <v-toolbar :height="50">
                {{ props.reference.type == 'release' ? 'Gate Out' : 'Gate In' }} - Containers List
            </v-toolbar>
            <v-toolbar :height="50">
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="$emit('close')" @mouseover="onBeforeCloseWindow"></v-btn>
            </v-toolbar>
        </div>
        <div v-if="state.isShowingInformation" class="main-content-info">
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Reference</strong></v-list-item-title>
                <v-list-item-subtitle>{{ state.reference.reference }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Facility/Depot</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.depot__company ? state.reference.depot__company.name :
                        '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Freight Kind</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.freight_kind ?
                        utilities.capitalizeFirstLetter(state.reference.freight_kind) : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Cargo Condition</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.cargo_condition__repair_condition ?
                        state.reference.cargo_condition__repair_condition.name : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Booking Category</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.booking_category__booking_category ?
                        state.reference.booking_category__booking_category.name : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Size Type</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.survey_size_type__survey_size_type ?
                        state.reference.survey_size_type__survey_size_type.code + ' - ' +
                        state.reference.survey_size_type__survey_size_type.survey_type__survey_type.name : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Shipping Line/Operator</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.operator__company ? state.reference.operator__company.name
                        : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Leasing Company</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.leasing_company__company ?
                        state.reference.leasing_company__company.name : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item two-line style="flex-direction: column;align-items: flex-start;">
                <v-list-item-title><strong>Validity</strong></v-list-item-title>
                <v-list-item-subtitle>{{
                    state.reference.validity ? state.reference.validity : '&nbsp;'
                }}</v-list-item-subtitle>
            </v-list-item>
        </div>

        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue 
                id="containerGrid" 
                ref="containerGrid" 
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine" 
                :columnDefs="columnDefs" 
                :defaultColDef="defaultColDef" 
                :components="components"
                :rowData="data.rowData" 
                :pinnedTopRowData="data.pinnedTopRowData" 
                :gridOptions="gridOptions"
                :editType="editType" 
                @contextmenu="stopEditingOnRightclick">
            </ag-grid-vue>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
            </v-toolbar>
            <v-toolbar :height="40">
                <div style="display: flex;justify-content: right;align-items: center;"><strong>Completed/Total</strong>:
                    {{ gatedContainers }}/{{ totalContainers }}</div>
            </v-toolbar>
        </div>



        <DialogNeo dialog-title="Confirm" :show="state.dialog" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            Are you sure you want to delete the selected containers?
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="right">
                            <v-btn color="primary" prepend-icon="mdi-delete" @click="cancelDeleteContainer">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Cancel
                            </v-btn>
                            <v-btn color="error" prepend-icon="mdi-delete" @click="confirmDeleteContainer" style="margin-left: 15px;">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelDeleteContainer"></v-btn>
            </template>
        </DialogNeo>

        <DialogNeo :dialog-title=" 'Confirm Gate-' + (state.gateType=='in' ? 'In' : 'Out') " :show="state.dialogGate" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center" style="height: 100%;">
                        <v-col>
                            <v-btn color="primary" prepend-icon="mdi-plus-circle" width="100%" @click="confirmGateContainers(true)">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                WITH - stacker job
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" prepend-icon="mdi-minus-circle" width="100%" @click="confirmGateContainers(false)">
                                <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                </template>
                                WITHOUT - stacker job
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelGateContainers"></v-btn>
            </template>
        </DialogNeo>

        <DialogNeo dialog-title="Warning" :show="state.dialogWarning" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            {{ state.notGatedContainers.length }} container{{ state.notGatedContainers.length>1 ? "s have" : " has"}} a <b>Gate {{ props.reference.type === 'return' ? 'In' : 'Out' }} Date</b> set but {{ state.notGatedContainers.length>1 ? "were" : "was"}} not <b>Gated {{ props.reference.type === 'return' ? 'In' : 'Out' }}</b>.
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="center">
                            <v-btn color="primary" @click="state.dialogWarning = false">
                                Ok
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="state.dialogWarning = false"></v-btn>
            </template>
        </DialogNeo>

        <DialogNeo :dialog-title=" 'Confirm Gate-' + (props.reference.type=='return' ? 'In' : 'Out') + ' Revert'" :show="state.revertDialogGate" dialog-width="25%" dialog-height="unset">
            <template #content>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            Are you sure you want to <b>Revert</b> the container <b>{{ props.reference.type === 'return' ? 'Gate In' : 'Gate Out' }}</b> operation?
                        </v-col>
                    </v-row>
                    <v-row align="end" justify="center">
                        <v-col align="center">
                            <v-btn color="primary" @click="confirmRevertGateInOut()">
                                Ok
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #dialog-header-right>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="state.revertDialogGate = false"></v-btn>
            </template>
        </DialogNeo>        
    </div>
</template>

<script setup>



//IMPORTS
import jQuery from "jquery";
import { inject, toRaw, ref, reactive, onMounted, onUnmounted, nextTick, defineEmits, defineProps, getCurrentInstance, computed, onActivated, onDeactivated } from 'vue';
import { useAppStore } from '@/stores/app';
import { useContainerReferenceListDefinition } from '@/components/aggrid/definitions/useContainerReferenceListDefinition';
import { useCommon } from '@/components/aggrid/common/useCommon';
// If focusTrap is used then one can't select values from select boxes because these are appended to the body which are outside of the dialog window
//import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import Papa from 'papaparse'
import { useUtilities } from  '@/composables/useUtilities';



//PROPS
const store = useAppStore();

const props = defineProps({
    'reference': {  //params.data of reference
        type: Object,
        required: true,
        default: () => {
            return null;
        }
    }
});


//REFERENCES
const containerGrid = ref(null);



//INJECTIONS
const axios = inject('axios');
const storage = inject('storageManager');
const dayJs = inject("dayJs");



//EMITS
const emits = defineEmits(['close', 'data-update']);



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let columnApi2=ref(null);
let allColumnIds = [];
let instance = ref(getCurrentInstance());
let deactivatingCellEditors = false;



//CONSTANTS
const componentName = getCurrentInstance().type.name;
const componentId = getCurrentInstance().uid;
const $ = jQuery;
const editType = 'fullRow';
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []

})
const components = {
    AgGridVue
};
const fields = [

];
const gatedContainers = ref(0);
const totalContainers = ref(props.reference.quantity);
const state = reactive({
    newContainers: 0,
    allowUnpin: false,
    isEditing: 'no',
    dialog: false,
    dialogGate: false,
    revertDialogGate: false,
    dialogWarning: false,
    notGatedContainers: [],
    notGatedWarningShown: true,
    gateType: null,
    nodesToDelete: [],
    isAdding: false,    //While this is true one can't add another reference
    gridReady: false,
    firstVisibleColumn: null,
    isShowingInformation: true,
    reference: props.reference,
})



//COMPUTED
const triggerButtonsUpdate = ref(true);
const allowAdd = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowAdd(data, gridApi, state);
})
const allowEdit = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowEdit(data, gridApi, state);
})
const allowDelete = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowDelete(data, gridApi, state);
})
const allowRevert = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return false;
    let rows = gridApi.getSelectedNodes();
    if (rows.length==1){
        let row = rows[0];
        if ((props.reference.type=='return' && row.data.stock_container_status_id==2) || (props.reference.type=='release' && row.data.stock_container_status_id==5)){
            return true;
        }
    }
    return false;
})
const allowGateInOut = computed(() => {
    let t = triggerButtonsUpdate.value;
    if (!gridApi) return false;
    let rows = gridApi.getSelectedNodes();
    if (rows.length==1){
        let row = rows[0];
        if (row.data.stock_container_status_id==1){
            return true;
        }
    }
    return false;
})



//COMPOSABLES
const { common } = useCommon(containerGrid, state, gridApi2, columnApi2, data);
const { advancedAxios } = useAdvancedAxios(axios);
const { getContainerReferenceListDefinition } = useContainerReferenceListDefinition(props, store, dayJs);
const { keyboard } = useKeyboard();
const { utilities } = useUtilities();



const columnDefs = ref([])
const defaultColDef = reactive({...common.defaultColDef, ...{
    floatingFilter: true,
    floatingFilterComponentParams: {
        suppressFilterButton: false
    }
}});

const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    overlayNoRowsTemplate: "<strong>No containers</strong>",
    noRowsOverlayComponentParams: {
        click: () => { gridApi.hideOverlay(); addNewContainer(); }
    },
    onSortChanged: (e) => {
        //This method keeps the newly added records on top
        common.onSortChanged();
    },    
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },

    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        columnApi2.value = params.columnApi;
        common.onGridReady(params, getContainerReferenceListDefinition, columnDefs, state, populateGridWithData);
    },
    onRowDataChanged: () => {
        common.onRowDataChanged(columnApi);
    },
    onRowEditingStarted: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        common.onRowEditingStarted(params, state, componentId);
    },
    onRowEditingStopped: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onRowClicked: (params) => {
        if (params.data.id==""){
            gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn });
        }
    },
    onRowSelected: (params) => {
        //
    },
    onRowValueChanged: (params) => {
        if (params.data.id=='' || common.editedRowChanged(state, params.data)){        
            let validation = validateRecord(params);
            if (validation.valid) {
                params.data.valid = true;
                state.isEditing = 'no';

                let method = '';
                let url = '';
                if (params.data.id == '' || params.data.id == null) {
                    url = '/reference/' + props.reference.id + '/stockContainer';
                    method = 'post';
                } else {
                    url = '/stockContainer/' + params.data.id;
                    method = 'put';
                }

                let sendData = { ...params.data };

                let response ={
                    data: {
                        model: sendData
                    }
                }

                advancedAxios.sendRequest(componentId + (params.data.id ? params.data.id : params.data.uniqueId), { method: method, url: url, data: sendData })
                    .then(function (response) {
                        if (response.status === 200) {
                            //Update row with changes from backend
                            response.data.model.uniqueId = params.data.uniqueId;
                            response.data.model.valid = true;
                            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
                            //
                            store.addNotificationMessage('Container: ' + (params.data.unit_number ? params.data.unit_number : '') + ' saved!', 'success');
                            updateNotGatedContainerList(response.data.model);
                        } else {
                            params.data.valid = false;
                            params.node.setDataValue('valid', false);
                            store.addNotificationMessage('Container: ' + (params.data.unit_number ? params.data.unit_number : '') + ' could not be saved!\nReason: ' + response.data.message, 'error');
                        }
                        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
                        updateGatedContainers();
                    }).catch(function (error) {
                        if (error.message !== 'canceled') {
                            params.data.valid = false;
                            params.node.setDataValue("valid", false);
                        }
                    });
            } else {
                params.data.valid = false;
                store.addNotificationMessage("Container" + (params.data.id ? ": '" + params.data.id + "'" : '') + ' has invalid data.\n' + validation.message, 'error');
            }

            params.node.setDataValue('valid', params.data.valid);

            if (state.isAdding) {
                state.isAdding = false;
            }
        }
        state.isEditing='no';
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onSelectionChanged: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onRowDoubleClicked: (params) => {
    },
    onPinnedRowDataChanged: (params) => {
        state.allowUnpin = (data.pinnedTopRowData.length === 0) ? false : true;
    },
    onCellMouseDown: (params) => {
        common.onCellMouseDown(params, state);
    },
    tabToNextCell(params) {
        return common.tabToNextCell(params, gridApi);
    },
    onBodyScroll: (event) => {
        if (!deactivatingCellEditors && event.direction=='horizontal'){
            deactivatingCellEditors=true;
            common.deactivateAllCellEditors(gridApi);
            setTimeout( function(){ deactivatingCellEditors=false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
        }
    },
    onBodyScrollEnd: (event) => {
        //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
        deactivatingCellEditors=false;
    },
    onCellClicked: (params) => {
        common.onCellClicked(params, store);
    },
    onModelUpdated: () => {
        updateGatedContainers();
    }
}



//EVENTS
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onDeactivated(() => {
    keyboard.removeListener(componentId);
});
onMounted(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onUnmounted(() => {
    keyboard.removeListener(componentId);
    let containers = data.rowData == null ? [] : toRaw(data.rowData.filter(item => item.id != ''));
    let topPinnedContainers = data.pinnedTopRowData == null ? [] : toRaw(data.pinnedTopRowData.filter(item => item.id != ''));
    let updateData = {
        referenceId: props.reference.id,
        containers: containers.concat(topPinnedContainers)
    }
    emits('data-update', updateData);
})



//METHODS
const onKeyPressHandler = (event) => {
    if (state.containersDialog === false) {
        if (event.shiftKey) {
            if (event.key == '+') { addNewContainer(); }
        } else {
            common.onKeyPressHandler(event, gridApi, data, state);
        }
    }
}

const openFileDialog = async () => {
    return new Promise((resolve, reject) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            let file = e.target.files[0];
            resolve(file);
        }
        input.click();
    });
}


const populateGridWithData = () => {
    state.gridReady = false;
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });
    if (storage.enabled()) {
        storage.getData({
            id: 'reference',
            models: [
                { url: 'stockContainer/synchronize/stockContainers', map: modelSubmodelMap.model, timer: 10000 },
            ],
            onError: function () { common.normalServerReload('/reference/' + props.reference.id + '/stockContainer', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid) },
            onUpdate: function () { gridApi.setRowData(data.rowData) }
        });
    } else {
        common.normalServerReload('/reference/' + props.reference.id + '/stockContainer', axios, state, gridApi, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
    }
}

const onSetupGrid = (response) => {
    state.reference = response.data.reference;
    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const addNewContainer = (type) => {
    gridApi.setFilterModel(null);
    setTimeout(function(){
        if (data.rowData != null && (data.rowData.length + data.pinnedTopRowData.length) >= props.reference.quantity) {
            store.addNotificationMessage('There are already <strong>' + (data.rowData.length + data.pinnedTopRowData.length) + '</strong> containers added to this list. The total number of containers has been reached.', 'info');
        } else {
            gridApi.hideOverlay();
            if (state.isEditing != 'no') {
                //
            }
            state.isAdding = true;
            let newContainer = prepareRecord({ 
                new: 1,
                type: type 
            });
            data.rowData.unshift(newContainer);
            gridApi.setRowData(data.rowData);
            nextTick(() => {
                let node = gridApi.getRowNode(newContainer.uniqueId); 
                if (node){
                    gridApi.startEditingCell({rowIndex: node.rowIndex, colKey:  state.firstVisibleColumn});
                    state.isEditing='existing';
                }
            })
        }
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }, 1);
}

const importCsv = async () => {
    const file = await openFileDialog();
    const fileText = await file.text()
    const parsedData = Papa.parse(fileText, { header: true }).data

    let newData = [];
    for(let i = 0; i < parsedData.length; i++) {
        newData.push(prepareRecord(parsedData[i]));
    }

    data.rowData = newData;

    // send call to backend to save data
     axios.post('/stockContainer/' + props.reference.id + '/import', { data: newData })
         .then(response => {
             //
         })
         .catch(error => {
             //
         })
}

const editContainer = () => {
    gridApi.stopEditing();
    let rows = gridApi.getSelectedNodes();
    if (rows.length>0){
        let row = rows[0];
        gridApi.startEditingCell({ rowIndex: row.rowIndex, colKey: state.firstVisibleColumn });
        state.isEditing = 'existing';
    }
}

//Delete containers
const deleteContainer = () => {
    if (!common.deleteEditingRow(gridApi, data, state)) {
        let nodes = gridApi.getSelectedNodes();
        let nonDeletable = 0;
        const total = nodes.length;
        state.nodesToDelete = [];
        nodes.forEach(item => {
            state.nodesToDelete.push(item);

        });
        if (nonDeletable > 0) {
            if (total > 1) {
                if (nonDeletable == total) { store.addNotificationMessage("The containers already have a Gate-Out date and can't be deleted", 'warning'); }
                else if (nonDeletable > 0) { store.addNotificationMessage("Some containers already have a Gate-Out date and can't be deleted", 'warning'); }
            } else {
                if (nonDeletable == 1) {
                    store.addNotificationMessage("The container already has a Gate-Out date and can't be deleted", 'warning');
                }
            }
        } else {
            state.dialog = true;
        }
    } else {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }
}
const confirmDeleteContainer = () => {
    state.dialog = false
    let delData = [];
    let max = state.nodesToDelete.length;
    for (let i = 0; i < max; i++) {
        delData.push(state.nodesToDelete[i].data.id);
    }
    axios.post('/reference/' + props.reference.id + '/unassign/' + delData.join(',')).then(function (response) {
        if (response.status == 200) {
            let deleted = response.data.deleted.length>0 ? response.data.deleted.split(",") : [];
            let max = deleted.length;
            for (let i = 0; i < max; i++) {
                let delIndex = data.rowData.findIndex(o => o.id === parseInt(deleted[i]));
                data.rowData.splice(delIndex, 1);
                gridApi.setRowData(data.rowData);
            }
            state.nodesToDelete = [];
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
            updateGatedContainers();
        } else {
            //
        }
    }).catch(function (error) { });
}
const cancelDeleteContainer = () => {
    state.dialog = false
}

//Gate In Containers
const gateContainers = (type) => {
    let nodes = gridApi.getSelectedNodes();
    let nonGateInable = 0;
    const total = nodes.length;
    state.nodesToGate = [];
    nodes.forEach(item => {
        state.nodesToGate.push(item);
    });
    state.gateType = type;
    state.dialogGate = true;
}
const confirmGateContainers = (withJob) => {
    state.dialogGate = false
    let gateData = [];
    let max = state.nodesToGate.length;
    for (let i = 0; i < max; i++) {
        gateData.push(state.nodesToGate[i].data.id);
    }
    axios.post('/stockContainer/' + gateData.join(',') + '/' + (state.gateType=='in' ? 'gateIn' : 'gateOut') + '/' + (withJob ? 'withJob' : 'withoutJob')).then(function (response) {
        if (response.status === 200) {
            //Update row with changes from backend
            response.data.model.uniqueId = state.nodesToGate[0].data.uniqueId;
            common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);
            //
            updateNotGatedContainerList(response.data.model);
            if (withJob){
                store.addNotificationMessage("A job was created for container " + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + " and it's going to be gated " + state.gateType+'!', 'success');
            }else{
                store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' gated ' + state.gateType+'!', 'success');
            }

            state.nodesToGate = [];
        } else {
            state.nodesToGate[0].data.valid = false;
            state.nodesToGate[0].node.setDataValue('valid', false);
            store.addNotificationMessage('Container: ' + (state.nodesToGate[0].data.unit_number ? state.nodesToGate[0].data.unit_number : '') + ' could not be gated ' +state.gateType+'!\nReason: ' + response.data.message, 'error');
        }
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        updateGatedContainers();

    }).catch(function (error) { });
}
const cancelGateContainers = () => {
    state.dialogGate = false
}


const cancelEditContainer = () => {
    state.dialog = false
}
const finishEditingContainer = (cancel = false) => {
    gridApi.stopEditing();
    state.isEditing = 'no';
}

const toggleFilterToolbar = () => {
    defaultColDef.floatingFilter = !defaultColDef.floatingFilter;
}

const numberComparator = (a, b) => {
    return a - b;
}

const toggleShowInformation = () => {
    state.isShowingInformation = !state.isShowingInformation;
}

const stopEditingOnRightclick = () => {
    common.stopEditingOnRightClick(state, gridApi);
}

const validateRecord = (params) => {
    return {
        valid: true
    }
}

const updateGatedContainers = () => {
    let gated = 0;
    if (props.reference.type === 'return') {
        if (data.rowData !== null) {
            gated += data.rowData.filter(item => {
                return item['gate_in_date'] && item['stock_container_status_id']==1 ? true : false;
            }).length;
        }
        if (data.pinnedTopRowData !== null) {
            gated += data.pinnedTopRowData.filter(item => {
                return item['gate_in_date'] && item['stock_container_status_id']==1 ? true : false;
            }).length;
        }
    } else {
        if (data.rowData !== null) {
            gated += data.rowData.filter(item => {
                return item['gate_out_date'] && item['stock_container_status_id']==2 ? true : false;
            }).length;
        }
        if (data.pinnedTopRowData !== null) {
            gated += data.pinnedTopRowData.filter(item => {
                return item['gate_out_date'] && item['stock_container_status_id']==2 ? true : false;
            }).length;
        }
    }
    gatedContainers.value = gated;
}

const onBeforeCloseWindow = () => {
    if (!state.notGatedWarningShown && state.notGatedContainers.length>0){
        //Don't show any notification until it's properly implemented
        state.dialogWarning = true;
        state.notGatedWarningShown = true;
    }
}
const copyValues = () => {
    //
}

const updateNotGatedContainerList = (model) => {
    if (props.reference.type=='return') {
        if (model.stock_container_status_id == 1 && model.gate_in_date != null){
            addToNotGatedContainerList(model.uniqueId);
        }else{
            removeFromNotGatedContainerList(model.uniqueId);
        }
    }
    if (props.reference.type=='release') {
        if ((model.stock_container_status_id == 1 || model.stock_container_status_id == 2) && model.gate_out_date != null){
            addToNotGatedContainerList(model.uniqueId);
        }else{
            removeFromNotGatedContainerList(model.uniqueId);
        }
    }
}

const addToNotGatedContainerList = (uniqueId) => {
    let found = false;
    state.notGatedContainers.find(uId => { 
        if (uId === uniqueId){
            found = true;
            return true;
        }
    });
    if (!found){
        state.notGatedContainers.push(uniqueId);
        state.notGatedWarningShown = false;
    }
}

const removeFromNotGatedContainerList = (uniqueId) => {
    let foundIndex = state.notGatedContainers.findIndex(uId =>{ 
        return uId === uniqueId 
    } );
    if (foundIndex>=0){
        state.notGatedContainers.splice(foundIndex, 1);
    }
}

const revertGateInOut = () => {
    state.revertDialogGate = true;
}
const confirmRevertGateInOut = () => {
    state.revertDialogGate = false;
    let row;
    let rows = gridApi.getSelectedNodes();
    if (rows.length==1){
        row = rows[0];
        if ((props.reference.type=='return' && row.data.stock_container_status_id==2) || (props.reference.type=='release' && row.data.stock_container_status_id==5)){
            //
        }else{
            return false;
        }
    }else{
        return false;
    }
    let container = row.data;

    let url = '/stockContainer/' + container.id + '/' + (props.reference.type=='return' ? 'revertGateIn' : 'revertGateOut/returnReferenceContainer');
    advancedAxios.sendRequest(componentId + container.uniqueId, { method: 'post', url: url })
        .then(function (response) {
            if (response.status === 200) {
                response.data.model.uniqueId = container.uniqueId;
                response.data.model.valid = true;
                common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);                
                store.addNotificationMessage( (props.reference.type=='return' ? 'Gate-In' : 'Gate Out') + ' reverted for container: ' + (container.unit_number ? container.unit_number : ''), 'success');
                updateNotGatedContainerList(response.data.model);
            } else {
                store.addNotificationMessage((props.reference.type=='return' ? 'Gate In' : 'Gate Out') + ' for container: ' + (container.unit_number ? container.data.unit_number : '') + ' could not be reverted!\nReason: ' + response.data.message, 'error');
            }
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
            //updateGatedContainers();
        }).catch(function (error) {
            //
        });
}

const prepareRecord = (item) => {
    return {
        ...common.prepareRecord(item)
        ,
        ...{
            valid: item.valid ? item.valid : true,
            actions: item.actions ? item.actions : "",
            id: item.id ? item.id : '',
            attributes: null,
            unit_number: item.unit_number ? item.unit_number : null,
            construction_year: item.construction_year ? item.construction_year : null,
            gate_in_date: item.gate_in_date ? item.gate_in_date : null,
            gate_out_date: item.gate_out_date ? item.gate_out_date : null,
            ib_actual_visit_mode: item.ib_actual_visit_mode ? item.ib_actual_visit_mode : '',
            ib_actual_visit: item.ib_actual_visit ? item.ib_actual_visit : '',
            ob_actual_visit_mode: item.ob_actual_visit_mode ? item.ob_actual_visit_mode : '',
            ob_actual_visit: item.ob_actual_visit ? item.ob_actual_visit : '',
            inbound_carrier_id: item.inbound_carrier_id ? item.inbound_carrier_id : '',
            outbound_carrier_id: item.outbound_carrier_id ? item.outbound_carrier_id : '',
            returning_company_name: item.returning_company_name ? item.returning_company_name : '',
            insurance_type: item.insurance_type ? item.insurance_type : '',
            insurance_amount: item.insurance_amount ? item.insurance_amount : '',
            updated_at: item.updated_at ? item.updated_at : '',
            color: item.color ? item.color : null,
            floor: item.floor ? item.floor : null,
            temperature_type: item.temperature_type ? item.temperature_type : '',
            temperature: item.temperature || item.temperature==0 ? item.temperature : '',
            humidity: item.humidity ? item.humidity : '',
            ventilation: item.ventilation ? item.ventilation : '',
            condition: item.condition ? item.condition : null,
            reference_comment: item.reference_comment ? item.reference_comment : '',
            last_on_hire_date: item.last_on_hire_date ? item.last_on_hire_date : '',
            stock_container_status_id: item.stock_container_status_id ? item.stock_container_status_id : '',
            created_by: item.created_by__user ? item.created_by__user.name : '',
            updated_by: item.updated_by__user ? item.updated_by__user.name : '',
            stock_container_status__stock_container_status: item.stock_container_status__stock_container_status ? item.stock_container_status__stock_container_status : '',
            cargo_condition_id: item.report__report ? item.report__report.cargo_condition : '',
            inspection_criteria: item.inspection_criteria ? item.inspection_criteria : '',
            run_state: item?.report__report ? item.report__report.state : '',
            repair_criteria: item.report__report ? item.report__report.repair_criteria : '',
            sale_grade: item.report__report ? item.report__report.sale_grade : '',
            machinery_manufacturer: item?.report__report?.machinery_manufacturer__manufacturer ? item?.report__report?.machinery_manufacturer__manufacturer.name : '',
            dpp: item.dpp ? item.dpp : '',
        }
    }
}

const modelSubmodelMap = {
    model: {
        'stockContainers': { prepareRecord: prepareRecord, target: () => { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
        'insurance_type': { target: function () { return columnDefs.value.find(def => def.field === 'insurance_type') } },
        'ib_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ib_actual_visit_mode') } },
        'ob_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ob_actual_visit_mode') } },
        'condition': { target: function () { return columnDefs.value.find(def => def.field === 'condition') } },
        'inbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'inbound_carrier_id').cellEditorParams.valueList } },
        'outbound_carrier_id': { prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'outbound_carrier_id').cellEditorParams.valueList } },
        'color': { target: function () { return columnDefs.value.find(def => def.field === 'color') } },
        'floor': { target: function () { return columnDefs.value.find(def => def.field === 'floor') } },
        'temperature_type': { target: function () { return columnDefs.value.find(def => def.field === 'temperature_type') } },
        'stock_container_status_id': { target: function () { return columnDefs.value.find(def => def.field === 'stock_container_status_id') } },
        'inspection_criteria': { target: function () { return columnDefs.value.find(def => def.field === 'inspection_criteria') } },
        'cargo_condition_id': { id: 'code', target: function () { return columnDefs.value.find(def => def.field === 'cargo_condition_id') } },
    }
};

</script>

<script>
export default {
    name: 'ContainerReferenceList',
    customOptions: {}
}
</script>